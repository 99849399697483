import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Solicitud from "./pages/solicitud/Solicitud"
import Indicadores from './pages/indicadores/Indicadores';
import Seguimiento from './pages/seguimineto/Seguimiento';
import Calendario from './pages/calendario/Calendario';
import Login from './pages/login/Login';
import Documentos from './pages/documentacion/Documentos';
import Home from './pages/home/Home';
import Administracion from './pages/admin/administracion';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children:[
      {
        path:"",
        element: <Home/>
      },
      {
        path:"solicitud",
        element: <Solicitud/>,
      },
      {
        path:"indicadores",
        element: <Indicadores/>
      },
      {
        path:"seguimiento",
        element: <Seguimiento/>
      },
      {
        path:"contratos",
        element: <Documentos/>
      },
      {
        path:"calendario",
        element: <Calendario/>
      },
      {
        path:"administracion",
        element: <Administracion/>
      }
    ],
  },
  {
    path:"login",
    element: <Login/>
  }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
