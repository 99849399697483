import React, {useState, useEffect} from "react";
import { PowerBIEmbed } from 'powerbi-client-react';
import * as models from 'powerbi-models';
import { Box, CircularProgress } from "@mui/material";
import { globals } from "../../data/Globals";

const options = {
    headers: {
        'Content-Type': 'application/json',
        'x-cors-api-key': 'test_d442a6164704c7a46e10d107a428a2f208d0be1d87c9d78effac1bf0928e53f9',
    },
    method: 'POST',
    body: JSON.stringify({
        name: "Azure"
    })
};
    
const proxyUrl = 'https://proxy.cors.sh/';
const targetUrl = 'https://ekogest-pbitoken.azurewebsites.net/api/EkoGest_AccessToken?code=A1U-aNMMDXCjI2Tu1mmdb6zI9uwyNlufC408tQIjF8MPAzFuD1mHYg==';

export default function PbiEmbedded(){
    const [accessToken, setAccessToken] = useState('');
    const [biURL, setBiURL] = useState('');

    useEffect(() => {
        const getAccessToken = async () => {
            const response = await fetch(proxyUrl + targetUrl, options)
            const text = await response.text();
            //console.log('Response text:', text); // log the response text
            try {
                const data = text.replaceAll('"','');
                setAccessToken(data);
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        };

        async function fetchCredentials(){
            let user_token = localStorage.getItem("ekogest_user_token");

            const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': process.env.REACT_APP_API_KEY,
                },
                body: JSON.stringify({
                    'page':'BIcredentials',
                    'token': user_token,
                })
            })
      
            if(response.status !== 200){
                const message = await response.json();
                console.log(message)
                return
            }
      
            const credential_url = await response.json();
      
            setBiURL(credential_url)
        }

        fetchCredentials().then(
            getAccessToken()
        )
        
    }, []);

    const embedConfig = {
        type:'report',
        embedUrl:biURL,
        accessToken:accessToken,
        tokenType: models.TokenType.Aad,
        pageView: "fitToWidth", 
        settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false         
        }
    };


    const eventHandlers = new Map([
        ['loaded', () => {
            console.log('Report loaded');
        }],
        ['rendered', () => {
            console.log('Report rendered');
        }],
        ['error', (event) => {
            console.log(event.detail);
        }]
    ]);
    
    const cssClassName = "Embed-container";
    
    const getEmbeddedComponent = (embeddedReport) => {
        window.report = embeddedReport;
    };


    return(
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
            {accessToken !== '' ? <PowerBIEmbed embedConfig={embedConfig} eventHandlers={eventHandlers} cssClassName={cssClassName} getEmbeddedComponent={getEmbeddedComponent}/>: <CircularProgress sx={{marginTop:"50px"}} size={100} />} 
        </Box>
    );
}