import React from "react";
import { Fab, Grid, Typography } from "@mui/material";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import backgroundLogin from "../../images/EkoLogin.png";

const FrontPage = () => {
  
    return(
        <Grid position="absolute" top={0} left={0}  container sx={{backgroundImage: `url(${backgroundLogin})`,backgroundRepeat: "no-repeat",backgroundPosition: "center", backgroundSize:"cover", height:"100vh"}}>
            <Fab sx={{position:'absolute', bottom:16, right:16}} color="primary" variant="extended" href="https://www.ekologica.es/contacto/">
                <Typography fontWeight={65}>
                    CONTACTANOS
                </Typography>
                <ForwardToInboxIcon sx={{ ml: 2 }}/>
            </Fab>
        </Grid>        
    )
}

export default FrontPage