
export const columns = [
    {   
        id: 'ID_RECOGIDA', 
        label: 'ID', 
        align: 'center',
        minWidth: 50 },
    {   
        id: 'GESTOR', 
        label: 'Gestor', 
        align: 'center',
        minWidth: 100 },
    {   
        id: 'CENTRO_GESTOR', 
        label: 'Centro Gestor', 
        align: 'center',
        minWidth: 100 },
    {   
        id: 'PRODUCTOR', 
        label: 'Productor', 
        align: 'center',
        minWidth: 100 },
    {   
        id: 'CENTRO_PRODUCTOR', 
        label: 'Centro Productor', 
        align: 'center',
        minWidth: 100 },
    {
        id: 'DESC_LER',
        label: 'LER',
        minWidth: 100,
        align: 'center',
        //align: 'right',
        //format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'FECHA_RECOGIDA',
        label: 'Fecha de recogida',
        align: 'center',
        minWidth: 100,
    },
    {
        id: '_ESTADO',
        label: 'Estado',
        align: 'center',
        minWidth: 100,
    },
];