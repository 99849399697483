import React, { useState, useEffect } from "react";
import { Grid, Link, Box, CircularProgress, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Typography, TextField, Autocomplete, TablePagination } from "@mui/material";
import { columns } from "../../components/tabla_documentos/columns";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { UploadToBlob } from "../../components/blob/blob_storage";
import AddIcon from '@mui/icons-material/PostAdd';
import { NewDocumentModal } from "../../components/new_document_card/NewDocumentModal";
import { NewNotifiaction } from "../../components/new_notification/NewNotification";
import { globals } from "../../data/Globals";
import { MD5 } from "crypto-js";
import { useNavigate } from "react-router-dom";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const Documentos = () => {
    const navigate = useNavigate();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filtroGestor, setFiltroGestor] = useState(null);
    const [filtroLer, setFiltroLer] = useState(null);
    const [filtroProductor, setFiltroProductor] = useState(null);
    const [filtroCentroGestor, setFiltroCentroGestor] = useState(null);
    const [filtroCentroProductor, setFiltroCentroProductor] = useState(null);
    const [filteredRows, setFilteredRows] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [showNotif, setShowNotif] = useState(false);

    const [loadingTable, setLoadingTable] = useState(true);
    const [resetKey, setResetKey] = useState(0);
    const [rowInfo, setRowInfo] = useState({gestor:"", centro_gest:"", productor:"", centro_prod:"", ler:""})

    useEffect(()=>{
      async function fetchDocumentos(){
        const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
          method:'POST',
          headers: {
              'Content-Type': 'application/json',
              'authorization': process.env.REACT_APP_API_KEY,
          },
          body: JSON.stringify({
              'page':'Documentos',
              'token':globals.user.TOKEN
          })
        })
  
        if(response.status !== 200){
          const message = await response.json();
          console.log(message)
          return
        }
  
        const documentos_data = await response.json();
        globals.Documentos = documentos_data

        setLoadingTable(false)
        setResetKey((value)=>value+1)
      }
      fetchDocumentos()
      console.log(globals)
    },[])

    const handleFileUpload = (event, extra, gestor, centro_gest, productor,centro_prod, ler) => {
      async function storeDocURL(){
        const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
          method:'POST',
          headers: {
              'Content-Type': 'application/json',
              'authorization': process.env.REACT_APP_API_KEY,
          },
          body: JSON.stringify({
              'page':'DocURL',
              'type':extra,
              'doc_name': file_name,
              'gestor':gestor,
              // 'centro':centro,
              'centro_gest': centro_gest,
              'centro_prod':centro_prod,
              'productor':productor,
              'ler':ler
          })
        })
  
        if(response.status !== 200){
          const message = await response.json();
          console.log(message)
          return
        }

        navigate(0)
      }

        // const token = MD5(new Date().getTime()).toString()
        const token = MD5(extra + gestor + productor + centro_gest + centro_prod + ler).toString()
        const file_name = event.target.files[0].name.replace(".pdf",token+".pdf");
        UploadToBlob(event.target.files[0], file_name);
        storeDocURL()
    }

    const deleteFile = (type, gestor, centro_gest, productor,centro_prod, ler)=>{
      async function deleteDoc(){
        const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
          method:'POST',
          headers: {
              'Content-Type': 'application/json',
              'authorization': process.env.REACT_APP_API_KEY,
          },
          body: JSON.stringify({
              'page':'DocURL',
              'type':type,
              'gestor':gestor,
              // 'centro':centro,
              'centro_gest': centro_gest,
              'centro_prod':centro_prod,
              'productor':productor,
              'ler':ler
          })
        })
  
        if(response.status !== 200){
          const message = await response.json();
          console.log(message)
          return
        }

        navigate(0)
      }

      deleteDoc()
    }

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(event.target.value);
      setPage(0);
    };

    const openPopup = () => {
      setShowPopup(true)
    }

    const openNotif = () => {
      setShowNotif(true)
    }
    
    function uniqueArray (obj, index, self){
        return index === self.findIndex((o) => (
            o.label === obj.label
        ))
    };

    function filterOptions() {
        let filter_gest = []
        globals.Documentos.forEach((row)=>{filter_gest.push({
            value: row.GESTOR,
            label: row.GESTOR,
          })})

        let filter_prod = []
        globals.Documentos.forEach((row)=>{filter_prod.push({
            value: row.PRODUCTOR,
            label: row.PRODUCTOR,
          })})

        let filter_centro_gestor = []
        globals.Documentos.forEach((row)=>{filter_centro_gestor.push({
            value: row.CENTRO_GESTOR,
            label: row.CENTRO_GESTOR,
          })})
        
        let filter_centro_productor = []
        globals.Documentos.forEach((row)=>{filter_centro_productor.push({
            value: row.CENTRO_PRODUCTOR,
            label: row.CENTRO_PRODUCTOR,
          })})

        let filter_ler = []
        globals.Documentos.forEach((row)=>{filter_ler.push({
            value: row.DESC_LER,
            label: row.DESC_LER,
          })})

        return [filter_gest.filter(uniqueArray), filter_ler.filter(uniqueArray), filter_prod.filter(uniqueArray), filter_centro_gestor.filter(uniqueArray), filter_centro_productor.filter(uniqueArray)]
    }

      useEffect(()=>{
        let newRow = []
        globals.Documentos.forEach((row) => {
          if((filtroGestor && !filtroGestor.includes(row.GESTOR)) || (filtroLer && !filtroLer.includes(row.DESC_LER)) || (filtroProductor && !filtroProductor.includes(row.PRODUCTOR)) || (filtroCentroGestor && !filtroCentroGestor.includes(row.CENTRO_GESTOR)) || (filtroCentroProductor && !filtroCentroProductor.includes(row.CENTRO_PRODUCTOR))){
            return
          }
          newRow.push(row)
        });
        setFilteredRows(newRow);
      }, [filtroGestor, filtroLer, filtroProductor, filtroCentroGestor, filtroCentroProductor, loadingTable])

    return(
    <>
    {
      loadingTable?
      <Box sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        pointerEvents: "none"
        }}>
        <CircularProgress size={200}/>
      </Box>
      :
    <Grid item xs={12}>
      <Grid container columnGap={2}>
        <Grid xs={0.8} display="flex" minWidth="80px">
        <Typography alignSelf="center">
          Filtrar por:
        </Typography>
        </Grid>
        <Grid xs={1.8} minWidth="100px">
          <Autocomplete
            key={resetKey}
            multiple
            options={filterOptions()[0]}
            getOptionLabel={(option)=>option.label}
            renderInput={(params) => (
              <TextField
              {...params}
              label="Gestor"
              />
          )}
          onChange={(event, newValue) => {
            if(newValue.length <= 0) {
                setFiltroGestor(null);
              return}
            let newFilter = [];
            newValue.map((res) => newFilter.push(res.value));
            setFiltroGestor(newFilter);
          }}
          />
        </Grid>
        <Grid xs={1.8} minWidth="100px">
          <Autocomplete
            key={resetKey}
            multiple
            options={filterOptions()[3]}
            getOptionLabel={(option)=>option.label}
            renderInput={(params) => (
              <TextField
              {...params}
              label="Centro Gestor"
              />
          )}
          onChange={(event, newValue) => {
            if(newValue.length <= 0) {
                setFiltroCentroGestor(null);
              return}
            let newFilter = [];
            newValue.map((res) => newFilter.push(res.value));
            setFiltroCentroGestor(newFilter);
          }}
          />
        </Grid>
        <Grid xs={1.8} minWidth="100px">
          <Autocomplete
            key={resetKey}
            multiple
            options={filterOptions()[2]}
            getOptionLabel={(option)=>option.label}
            renderInput={(params) => (
              <TextField
              {...params}
              label="Productor"
              />
          )}
          onChange={(event, newValue) => {
            if(newValue.length <= 0) {
                setFiltroProductor(null);
              return}
            let newFilter = [];
            newValue.map((res) => newFilter.push(res.value));
            setFiltroProductor(newFilter);
          }}
          />
        </Grid>
        <Grid xs={1.8} minWidth="100px">
          <Autocomplete
            key={resetKey}
            multiple
            options={filterOptions()[4]}
            getOptionLabel={(option)=>option.label}
            renderInput={(params) => (
              <TextField
              {...params}
              label="Centro Productor"
              />
          )}
          onChange={(event, newValue) => {
            if(newValue.length <= 0) {
                setFiltroCentroProductor(null);
              return}
            let newFilter = [];
            newValue.map((res) => newFilter.push(res.value));
            setFiltroCentroProductor(newFilter);
          }}
          />
        </Grid>
        <Grid xs={1.8} minWidth="100px">
          <Autocomplete
            key={resetKey}
            multiple
            options={filterOptions()[1]}
            getOptionLabel={(option)=>option.label}
            renderInput={(params) => (
              <TextField
              {...params}
              label="LER"
              />
          )}
          onChange={(event, newValue) => {
            if(newValue.length <= 0) {
              setFiltroLer(null);
              return}
            let newFilter = [];
            newValue.map((res) => newFilter.push(res.value));
            setFiltroLer(newFilter);
          }}
          />
        </Grid>
        <Grid item xs={1} display="flex" justifyContent="end" alignItems="end">
           <IconButton size="small" color="secondary" onClick={openPopup}>
            <AddIcon sx={{fontSize:"48px"}} />
           </IconButton>
        </Grid>
      </Grid>
        <Paper sx={{ width: '98.5%', overflow:"hidden", mt:"15px"}}>
            <TableContainer sx={{ maxHeight: "100%" }} key={resetKey}>
                <Table stickyHeader aria-label="sticky table" key={resetKey}>
                    <TableHead>
                        <TableRow>
                            {columns.map((column)=>(
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    width={column.width ? column.width : "auto"}
                                    style={{ minWidth: column.minWidth, maxWidth: column.maxWidth, backgroundColor:"#2b6244", color:"white"}}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody key={resetKey}>
                        {filteredRows ? 
                        filteredRows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                            return <TableRow>
                                {columns.map((column)=>{
                                    return <TableCell
                                    style={{borderLeft: column.id === "CONTRATO" || column.id === "NOTIFICACION" ? '1px solid #ddd':'none'}}
                                    key={column.id}
                                    align={column.align}>
                                        {!row[column.id] ? 
                                        //si no hay datos
                                        column.id === "NOTIFICACION" && globals.LER_Peligrosidad[row.DESC_LER]?
                                            <IconButton onClick={()=>{ setRowInfo({gestor:row.GESTOR, centro_gest:row.CENTRO_GESTOR, productor:row.PRODUCTOR,centro_prod:row.CENTRO_PRODUCTOR, ler:row.DESC_LER}); openNotif()}}>
                                                <UploadFileIcon/>
                                            </IconButton>
                                            :
                                            column.id === "CONTRATO" ?
                                            <IconButton component="label" >
                                                <input hidden accept=".pdf" type="file" onChange={(event)=> handleFileUpload(event,'Contrato', row.GESTOR, row.CENTRO_GESTOR, row.PRODUCTOR, row.CENTRO_PRODUCTOR, row.DESC_LER)}/>
                                            <UploadFileIcon/>
                                            </IconButton>
                                            :
                                            "-"
                                          :    
                                          //si hay datos
                                          column.id === "NOTIFICACION" ?    
                                          <Grid container>
                                            <Grid item xs={9} display="flex" alignItems="center" justifyContent="center">
                                            <Link href={row[column.id]} color='secondary' target="_blank" rel="noreferrer" overflow="hidden" title={row[column.id].replace("https://bsekologica1.blob.core.windows.net/ekogest/ekogest/","").replace(row[column.id].substr(-36),".pdf")}>                                     
                                              {row[column.id].replace("https://bsekologica1.blob.core.windows.net/ekogest/ekogest/","").replace(row[column.id].substr(-36),".pdf")}
                                            </Link> 
                                            </Grid>
                                            <Grid item xs={1.5}>
                                            <IconButton onClick={()=>{ setRowInfo({gestor:row.GESTOR, centro_gest:row.CENTRO_GESTOR, productor:row.PRODUCTOR,centro_prod:row.CENTRO_PRODUCTOR, ler:row.DESC_LER}); openNotif()}}>
                                                <UploadFileIcon/>
                                            </IconButton>
                                            </Grid>
                                            <Grid item xs={1.5}>
                                            <IconButton color="error" onClick={()=>{ deleteFile("deleteNotificacion", row.GESTOR, row.CENTRO_GESTOR, row.PRODUCTOR, row.CENTRO_PRODUCTOR, row.DESC_LER); }}>
                                                <DeleteForeverIcon/>
                                            </IconButton>
                                            </Grid>
                                          </Grid>
                                          :
                                          column.id === "CONTRATO" ?
                                          <Grid container>
                                            <Grid item xs={9} display="flex" alignItems="center" justifyContent="center">
                                              <Link href={row[column.id]} color='secondary' target="_blank" rel="noreferrer">                                     
                                                {row[column.id].replace("https://bsekologica1.blob.core.windows.net/ekogest/ekogest/","").replace(row[column.id].substr(-36),".pdf")}
                                              </Link> 
                                            </Grid>
                                            <Grid item xs={1.5}>
                                              <IconButton component="label" >
                                                  <input hidden accept=".pdf" type="file" onChange={(event)=> handleFileUpload(event,'Contrato', row.GESTOR, row.CENTRO_GESTOR, row.PRODUCTOR,row.CENTRO_PRODUCTOR, row.DESC_LER)}/>
                                                <UploadFileIcon/>
                                              </IconButton>
                                            </Grid>
                                            <Grid item xs={1.5}>
                                              <IconButton color="error" onClick={()=>{ deleteFile("deleteContrato", row.GESTOR, row.CENTRO_GESTOR, row.PRODUCTOR, row.CENTRO_PRODUCTOR, row.DESC_LER); }}>
                                                <DeleteForeverIcon/>
                                              </IconButton>
                                            </Grid>
                                          </Grid>
                                          :
                                          row[column.id]
                                        }
                                    </TableCell>
                                })}
                            </TableRow>
                        })
                        :
                        globals.Documentos
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return <TableRow>
                                    {columns.map((column)=>{
                                        return <TableCell
                                        style={{borderLeft: column.id === "CONTRATO" || column.id === "NOTIFICACION" ? '1px solid #ddd':'none'}}
                                        key={column.id}
                                        align={column.align}>
                                            {!row[column.id] ? 
                                        //si no hay datos
                                        column.id === "NOTIFICACION" ?
                                            <IconButton onClick={openNotif}>
                                                <UploadFileIcon/>
                                            </IconButton>
                                            :
                                            column.id === "CONTRATO" ?
                                            <IconButton component="label" >
                                                <input hidden accept=".pdf" type="file" onChange={(event)=> handleFileUpload(event,'Contrato', row.GESTOR, row.CENTRO_GESTOR, row.PRODUCTOR, row.CENTRO_PRODUCTOR, row.DESC_LER)}/>
                                            <UploadFileIcon/>
                                            </IconButton>
                                            :
                                            "-"
                                          :    
                                          //si hay datos
                                          column.id === "NOTIFICACION" ?    
                                          <Link href={row[column.id]} color='secondary' target="_blank" rel="noreferrer">                                     
                                            {row[column.id].replace("https://bsekologica1.blob.core.windows.net/ekogest/ekogest/","").replace(row[column.id].substr(-36),".pdf")}
                                          </Link> 
                                          :
                                          column.id === "CONTRATO" ?
                                          <Link href={row[column.id]} color='secondary' target="_blank" rel="noreferrer">                                     
                                            {row[column.id].replace("https://bsekologica1.blob.core.windows.net/ekogest/ekogest/","").replace(row[column.id].substr(-36),".pdf")}
                                          </Link> 
                                          :
                                          row[column.id]
                                        }
                                        </TableCell>
                                    })}
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredRows ? filteredRows.length : globals.Documentos.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Resultados por pagina:"
            labelDisplayedRows={
              ({ from, to, count })=>{
                return '' + from + '-' + to + ' de ' + count
              }
            }
      />
        </Paper>
        <NewDocumentModal open={showPopup} onClose={() => setShowPopup(false)}/>
        <NewNotifiaction open={showNotif} onClose={() => setShowNotif(false)} info={rowInfo}/>
        </Grid>
        }
        </>
    )
}

export default Documentos