import React, { useState } from "react";
import { Grid, TextField, Paper, Button, Typography, Stack, IconButton, List, ListItem, ListItemAvatar, Avatar, ListItemText } from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import CancelIcon from '@mui/icons-material/Cancel';
import { ModalCancelation } from "./ModalCancelation";
import { globals } from "../../data/Globals";

export default function FormCancelacion({recogida}){
    const [showCancelationPopup, setCancelationPopup] = useState(false);

    const openCancelationPopup = ()=>{
        setCancelationPopup(true)
    }

    return(
        <Stack fullWidth mt={3}>
            <Grid container display="flex" justifyContent="space-between">
                <Grid item xs={3}>
                <Paper elevation={3}>
                <Grid container pr={2}>
                <Grid item xs={12}>
                    <Typography fontSize={24} m={2}>
                        <b>Documentos Adjuntos:</b> 
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <List>
                        <ListItem
                            secondaryAction={
                                <IconButton edge="end" size="small" component="a" href={recogida.CONTRATO} target="_blank" rel="noopener noreferrer">
                                    <FindInPageIcon fontSize="small"/>
                                </IconButton>
                            }
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    <InsertDriveFileIcon/>
                                </Avatar>    
                            </ListItemAvatar>
                            <ListItemText 
                                sx={{overflow:"hidden"}}
                                primary="Contrato"
                                secondary={recogida.CONTRATO.replace("https://bsekologica1.blob.core.windows.net/ekogest/ekogest/","").replace(recogida.CONTRATO.substr(-36),".pdf")}
                            />    
                        </ListItem>
                        {globals.LER_Peligrosidad[recogida.DESC_LER] ?
                        <ListItem
                            secondaryAction={
                                <IconButton edge="end" size="small" component="a" href={recogida.CONTRATO} target="_blank" rel="noopener noreferrer">
                                    <FindInPageIcon fontSize="small"/>
                                </IconButton>
                            }
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    <InsertDriveFileIcon/>
                                </Avatar>    
                            </ListItemAvatar>
                            <ListItemText 
                                sx={{overflow:"hidden"}}
                                primary={<Stack><Typography>
                                        NP o FA
                                        </Typography>
                                        <Typography color="secondary" fontWeight={900}>
                                        {"(" + recogida.TON_DISP + "Tn disponibles)"} 
                                        </Typography></Stack>}
                                secondary={recogida.NOTIFICACION.replace("https://bsekologica1.blob.core.windows.net/ekogest/ekogest/","").replace(recogida.NOTIFICACION.substr(-36),".pdf")}
                            />    
                        </ListItem>
                        : <></>}
                    </List>
                </Grid>
            </Grid>
                </Paper>
                </Grid>
                <Grid item xs={8.8}>
                    <Paper elevation={3}>
                        <Grid Grid container pr={2} pb={2.15} pl={2}>
                            <Grid item xs={12}>
                                <Typography fontSize={24} m={2}>
                                    <b>Datos Adicionales:</b> 
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                            <Grid container rowGap={3} mb={1}>
                        <Grid item>
                            <TextField
                            label="Tipo de Contenedores"
                            variant="outlined"
                            defaultValue={recogida.TIPO_CONTENEDOR}
                            sx={{
                                pointerEvents:"none"
                            }}
                            inputProps={{
                                readOnly: true
                            }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                            label="Número de contenedores"
                            variant="outlined"
                            defaultValue={recogida.RECOGIDA_CONTENEDOR + " - ("+ recogida.SUBSTITUDION_CONTENEDOR +" a substituir)"}
                            sx={{
                                pointerEvents:"none"
                            }}
                            inputProps={{
                                readOnly: true
                            }}
                            />
                        </Grid>                        
                        </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container display="flex" justifyContent="end" mt={4} mb={2}>
                <Grid item xs={12}display="flex" justifyContent="end">
                    <Button variant="contained" endIcon={<CancelIcon/>} size="large" color="error" onClick={openCancelationPopup}>
                        Cancelar Recogida
                    </Button>
                </Grid>
            </Grid>
            <ModalCancelation open={showCancelationPopup} onClose={()=>setCancelationPopup(false)} id={recogida.ID_RECOGIDA}/>
        </Stack>
    )
}