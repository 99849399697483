import React, { useState, useRef } from "react";
import { Modal, Box, Grid, Paper, InputAdornment, IconButton, InputLabel, OutlinedInput, FormControl, Typography, TextField, Button } from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AddIcon from '@mui/icons-material/Add';
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { MD5 } from "crypto-js";
import { UploadToBlob } from "../blob/blob_storage"; 

export const NewNotifiaction = ({open, onClose, info})=>{
    const navigate = useNavigate();

    const [notifDoc, setNotifDoc] = useState();
    const [notifInput, setNotifInput] = useState();
    const [notifDateInput, setNotifDateInput] = useState(dayjs(null));
    const [notifWeightInput, setNotifWheightInput] = useState();
    const [notifError, setNotifError] = useState(false);
    const [notifDateError, setNotifDateError] = useState(false);
    const [notifWeightError, setNotifWheightError] = useState(false);
    const [notifMsg, setNotifMsg] = useState("");
    const [notifDateMsg, setNotifDateMsg] = useState("");
    const [notifWeightMsg, setNotifWheightMsg] = useState("");
    const [disableButton, setDisableButton] = useState(false);
    const notifRef = useRef(null);
    const modalRef = useRef(null);

    const handleNotificacionUpdate = (event) => {
        setNotifDoc(event.target.files[0])
    }

    const submitData = () => {
        async function storeDocURL(_name){
            const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
              method:'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'authorization': process.env.REACT_APP_API_KEY,
              },
              body: JSON.stringify({
                  'page':'DocURL',
                  'type':'Notificacion',
                  'doc_name': _name,
                  'gestor':info.gestor,
                //   'centro':info.centro,
                  'centro_gest':info.centro_gest,
                  'centro_prod':info.centro_prod,
                  'productor':info.productor,
                  'ler':info.ler,
                  'caducidad': notifDateInput.format("DD-MM-YYYY"),
                  'toneladas': notifWeightInput
              })
            })
      
            if(response.status !== 200){
              const message = await response.json();
              console.log(message)
              return
            }
            
            navigate(0)
        }

        let error = false

        if(!notifDoc){
            setNotifError(true);
            setNotifMsg("Campo obligatorio");
            error = true;
        }
        if(notifDateInput.format("DD-MM-YYYY") === "Invalid Date"){
            setNotifDateError(true);
            setNotifDateMsg("Campo obligatorio");
            error = true;
        }
        if(!notifWeightInput){
            setNotifWheightError(true);
            setNotifWheightMsg("Campo obligatorio");
            error = true;
        }

        if(!error){
            setDisableButton(true)
            // const token = MD5(new Date().getTime()).toString()
            const token = MD5("Notificacion" + info.centro_gest + info.centro_prod + info.ler + notifDoc.name).toString()
            const file_name = notifDoc.name.replace(".pdf",token+".pdf");
            UploadToBlob(notifDoc, file_name);

            storeDocURL(file_name)
        }
    }

    return(
        <Modal open={open} onClose={onClose} sx={{zIndex:"1499"}} ref={modalRef}>
            <Box sx={{
            position: 'absolute',
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pointerEvents: "none"
            }}>
                <Paper sx={{width: "40vw", minWidth: "600px", display: "flex", alignItems: "center", pointerEvents:"auto"}}>
                    <Grid container display="flex" justifyContent="space-evenly" rowGap={3} mt={5} mb={5}>
                        <Grid item xs={10}>
                            <Typography variant="h5"><b>Añadir notificación previa</b></Typography>
                        </Grid>
                        <Grid item xs={10.5}>
                        <FormControl fullWidth>
                                <InputLabel>Notificación previa</InputLabel>
                                <OutlinedInput  
                                    label="Notificación previa"                    
                                    readOnly
                                    value={notifDoc ? notifDoc.name : ""}
                                    onClick={()=>(notifRef.current.click())}
                                    error={notifError}
                                    helperText={notifMsg}
                                    endAdornment={
                                        <InputAdornment>
                                            <IconButton component="label" sx={{pointerEvents:"none"}}>
                                                <input hidden accept=".pdf" ref={notifRef} type="file" onChange={(event)=>{handleNotificacionUpdate(event); setNotifError(false); setNotifMsg("")}}/>
                                                <InsertDriveFileIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            <DatePicker 
                                label="Caducidad de la notificación" 
                                format="DD/MM/YYYY" 
                                sx={{width:"100%"}}
                                disableOpenPicker
                                value={notifDateInput}
                                slotProps={{
                                    textField: {
                                      helperText: notifDateMsg,
                                      error: notifDateError
                                    },
                                }}
                                onChange={(value) => {setNotifDateInput(value); setNotifDateError(false); setNotifDateMsg("")}}
                                required
                            />
                        </Grid>
                        <Grid item xs={5} display="flex" alignItems="end" >
                            <TextField 
                            fullWidth
                            label="Peso de la notificación" 
                            type={"number"} 
                            inputProps = {{min:0,}} 
                            value={notifWeightInput}
                            error={notifWeightError}
                            helperText={notifWeightMsg}
                            onChange={(event)=>{setNotifWheightInput(event.target.value); setNotifWheightError(false); setNotifWheightMsg("")}}
                            required
                            />
                        </Grid>
                        <Grid item xs={10.5} display="flex" justifyContent="end" pt={3}>
                        <Button variant="contained" endIcon={<AddIcon/>} disabled={disableButton} onClick={submitData}>
                            <b>Añadir</b>
                        </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Modal>
    )
}