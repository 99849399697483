
import React, { useState, useRef } from "react";
import { Box, Modal, Paper, Typography, Grid, TextField, Autocomplete, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Button } from "@mui/material";
import { globals } from "../../data/Globals";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AddIcon from '@mui/icons-material/Add';
import { DatePicker } from "@mui/x-date-pickers";
import { MD5 } from "crypto-js";
import { UploadToBlob } from "../blob/blob_storage"; 
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

export const NewDocumentModal = ({open, onClose}) => {
    const navigate = useNavigate();

    const [gestInput, setGestInput] = useState("");
    const [prodInput, setProdInput] = useState("");
    const [lerInput, setLerInput] = useState("");
    const [centroGestorInput, setCentroGestorInput] = useState("");
    const [centroProductorInput, setCentroProductorInput] = useState("");
    const [notifDateInput, setNotifDateInput] = useState(dayjs(null));
    const [notifWeightInput, setNotifWheightInput] = useState();
    const [gestError, setGestError] = useState(false)
    const [prodError, setProdError] = useState(false);
    const [lerError, setLerError] = useState(false);
    const [centroGestorError, setCentroGestorError] = useState(false);
    const [centroProductorError, setCentroProductorError] = useState(false);
    const [notifDateError, setNotifDateError] = useState(false);
    const [notifWeightError, setNotifWheightError] = useState(false);
    const [notifDateMsg, setNotifDateMsg] = useState("");
    const [notifWeightMsg, setNotifWheightMsg] = useState("");
    const [gestMsg, setGestMsg] = useState("");
    const [prodMsg, setProdMsg] = useState("");
    const [lerMsg, setLerMsg] = useState("");
    const [centroGestorMsg, setCentroGestorMsg] = useState("");
    const [centroProductorMsg, setCentroProductorMsg] = useState("");
    const [contratoDoc, setContratoDoc] = useState();
    const [notifDoc, setNotifDoc] = useState();
    const contratoRef = useRef(null);
    const notifRef = useRef(null);

    const submitData = () => {
        
        async function storeDocURL(_contrato, _notificacion){
            const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
              method:'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'authorization': process.env.REACT_APP_API_KEY,
              },
              body: JSON.stringify({
                  'page':'DocURL',
                  'type': 'newLine',
                  'con_name': _contrato,
                  'not_name': _notificacion,
                  'gestor':globals.user.ROL==="gestor" || globals.user.ROL==="administrador" ? globals.user.PROPIETARIO : gestInput,
                  'centro_gest':globals.user.ROL==="gestor" || globals.user.ROL==="administrador" ? globals.user.CENTRO : centroGestorInput,
                  'centro_prod':globals.user.ROL==="productor" ? globals.user.CENTRO : centroProductorInput,
                // 'centro_gest':centroGestorInput,
                // 'centro_prod':centroProductorInput,
                  'productor':globals.user.ROL==="productor" ? globals.user.PROPIETARIO : prodInput,
                  'ler':lerInput,
                  'caducidad': notifDateInput.format("DD-MM-YYYY") === "Invalid Date" ? "" : notifDateInput.format("DD-MM-YYYY"),
                  'toneladas': notifWeightInput ? notifWeightInput : ""
              })
            })
      
            if(response.status !== 200){
              const message = await response.json();
              console.log(message)
              return
            }
            
            navigate(0)
        }

        let error = false

        if(!prodInput && globals.user.ROL === "gestor"){
            setProdError(true);
            setProdMsg("Campo obligatorio")
            error = true
            // console.log("Error - Gestor");
        }
        if(!lerInput){
            setLerError(true);
            setLerMsg("Campo obligatorio")
            error = true
            // console.log("Error - LER");

        }
        if(!centroGestorInput && globals.user.ROL === "productor"){
            setCentroGestorError(true);
            setCentroGestorMsg("Campo obligatorio")
            error = true
            // console.log("Error - CG");
        }
        if(!centroProductorInput && globals.user.ROL === "gestor"){
            setCentroProductorError(true);
            setCentroProductorMsg("Campo obligatorio")
            error = true
            // console.log("Error - CP");
        }
        if(!gestInput && globals.user.ROL === "productor"){
            setGestError(true);
            setGestMsg("Campo obligatorio")
            error = true
            // console.log("Error - PRODUCTOR");
        }
        if(notifDoc && notifDateInput.format("DD-MM-YYYY") === "Invalid Date"){
            setNotifDateError(true);
            setNotifDateMsg("Campo obligatorio")
            error = true
            // console.log("Error - Fecha");
        }
        if(notifDoc && !notifWeightInput){
            setNotifWheightError(true);
            setNotifWheightMsg("Campo obligatorio")
            error = true
            // console.log("Error - Peso");
        }
        if(notifDoc && notifWeightInput < 0){
            setNotifWheightError(true);
            setNotifWheightMsg("Introduce un número válido")
            error = true
            // console.log("Error - Numero Valido");
        }
        if(notifDoc && !globals.LER_Peligrosidad[lerInput]){
            setNotifDoc(null);
        }

        if(!error){
            let contratoName = "";
            let notifName = "";
            // console.log("NO Error");
            if(contratoDoc){
                // const token = MD5(new Date().getTime()).toString()
                const token = MD5("Contrato" + centroGestorInput + centroProductorInput + lerInput + contratoDoc.name).toString()
                contratoName = contratoDoc.name.replace(".pdf",token+".pdf");

                UploadToBlob(contratoDoc, contratoName);
            }
            if(notifDoc){
                // const token = MD5(new Date().getTime()).toString()
                const token = MD5("Notificacion" + centroGestorInput + centroProductorInput + lerInput + notifDoc.name).toString()
                notifName = notifDoc.name.replace(".pdf",token+".pdf");

                UploadToBlob(notifDoc, notifName);
            }
            
            storeDocURL(contratoName, notifName)
        }
    }

    const handleContratoUpdate = (event) => {
        setContratoDoc(event.target.files[0]);
    }

    const handleNotificacionUpdate = (event) => {
        setNotifDoc(event.target.files[0])
    }
    
    function getUniqueOptions(globals, gestInput) {
        let options = [];
    
        if (globals.user.ROL === "gestor") {
            options = globals.user.CENTRO;
        } 
        else if (globals.user.ROL === "administrador") {
            options = globals.CentrosGest[globals.user.PROPIETARIO] || [];
        } 
        else {
            options = globals.CentrosGest[gestInput] || [];
        }
    
        // Using a Map to eliminate duplicates based on a unique property (e.g., id)
        const unique = new Map(options.map(item => [item.id, item])); // Change 'id' to the actual property
        return Array.from(unique.values());
    }


    function getUniqueProductorOptions(globals, prodInput) {
        let options = [];
    
        if (globals.user.ROL === "productor") {
            options = globals.user.CENTRO; // Assuming this is an array
        } else {
            options = globals.CentrosProd[prodInput] || [];
        }
    
        // Using a Map to eliminate duplicates based on a unique property (e.g., id)
        const unique = new Map(options.map(item => [item.id, item])); // Change 'id' to the actual property
        return Array.from(unique.values());
    }
    

    return (<Modal open={open} onClose={() => {onClose();}} sx={{zIndex:"1500"}}>
        <Box sx={{
                position: 'absolute',
                width: "100vw",
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                pointerEvents: "none"
                }}>
            <Paper sx={{width: "85vw", minWidth: "1200px", display: "flex", alignItems: "center", pointerEvents:"auto"}}>
                <Grid container display="flex" justifyContent="space-evenly" rowGap={3} mt={5} mb={5}>
                    <Grid item xs={10}>
                        <Typography variant="h5"><b>Añadir documentos</b></Typography>
                    </Grid>
                    <Grid item xs={2.265}>
                        {
                            globals.user.ROL === "gestor" || globals.user.ROL === "administrador" ?
                            <TextField
                            fullWidth
                            label="Gestor"
                            defaultValue={globals.user.PROPIETARIO}
                            InputProps={{
                                readOnly:true
                            }}
                            />
                            :
                            <Autocomplete
                            fullWidth
                            filterSelectedOptions
                            value={gestInput}
                            onChange={(event, newValue) => {
                                setGestInput(newValue);
                                setGestError(false);
                                setGestMsg("");
                            }}
                            // options={globals.Gestors}
                            options={Array.from(new Set(globals.Gestors))}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={gestError}
                                    helperText={gestMsg}
                                    label="Gestor"
                                    required
                                />
                            )}
                            disablePortal                                 
                            />                               
                        }
                        
                    </Grid>
                    <Grid item xs={2.265}>
                        {globals.user.ROL === "gestor" ?
                        <TextField
                        fullWidth
                        label="Centro Gestor"
                        defaultValue={globals.user.CENTRO}
                        InputProps={{
                            readOnly:true
                        }}
                        />
                        :
                        <Autocomplete
                            fullWidth
                            filterSelectedOptions
                            value={centroGestorInput}
                            disabled={gestInput || globals.user.ROL ==="administrador" ? false : true}
                            onChange={(event, newValue) => {
                                setCentroGestorInput(newValue);
                                setCentroGestorError(false);
                                setCentroGestorMsg("");
                            }}
                            options={
                                globals.user.ROL==="gestor" ? globals.user.CENTRO : 
                                globals.user.ROL==="administrador" ? Array.from(new Set(globals.CentrosGest[globals.user.PROPIETARIO] || [])) :
                                Array.from(new Set(globals.CentrosGest[gestInput] || []))
                              }
                            // options={globals.user.ROL==="gestor" ?  globals.user.CENTRO : globals.user.ROL==="administrador" ? globals.CentrosGest[globals.user.PROPIETARIO] ? globals.CentrosGest[globals.user.PROPIETARIO] : [] : globals.CentrosGest[gestInput] ? globals.CentrosGest[gestInput] : []}
                            // options={getUniqueOptions(globals, gestInput)}
                            // options={globals.user.ROL==="gestor" ?  globals.user.CENTRO : globals.user.ROL==="administrador" ? Array.from(new Set(globals.CentrosGest[globals.user.PROPIETARIO])) ? Array.from(new Set(globals.CentrosGest[globals.user.PROPIETARIO])) : [] : Array.from(new Set(globals.CentrosGest[gestInput])) ? Array.from(new Set(globals.CentrosGest[gestInput])) : []}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={centroGestorError}
                                    helperText={centroGestorMsg}
                                    label="Centro Gestor"
                                    required
                                />
                            )}
                            disablePortal                                 
                        />}
                    </Grid>
                    <Grid item xs={2.265}>
                        {
                            globals.user.ROL === "productor" ?
                            <TextField
                            label="Productor"
                            fullWidth
                            defaultValue={globals.user.PROPIETARIO}
                            InputProps={{
                                readOnly:true
                            }}
                            />
                            :
                            <Autocomplete
                            fullWidth
                            filterSelectedOptions
                            value={prodInput}
                            onChange={(event, newValue) => {
                                setProdInput(newValue);
                                setProdError(false);
                                setProdMsg("");
                            }}
                            // options={globals.Productors}
                            options={Array.from(new Set(globals.Productors))}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={prodError}
                                    helperText={prodMsg}
                                    label="Productor"
                                    required
                                />
                            )}
                            disablePortal                                 
                        />    
                            }                 
                    </Grid>
                    <Grid item xs={2.265}>
                        {globals.user.ROL === "productor" ?
                        <TextField
                        label="Centro Productor"
                        fullWidth
                        defaultValue={globals.user.CENTRO}
                        InputProps={{
                            readOnly:true
                        }}
                        />
                        :
                        <Autocomplete
                            fullWidth
                            filterSelectedOptions
                            value={centroProductorInput}
                            disabled={prodInput && globals.user.ROL !=="productor" ? false : true}
                            onChange={(event, newValue) => {
                                setCentroProductorInput(newValue);
                                setCentroProductorError(false);
                                setCentroProductorMsg("");
                            }}
                            // options={globals.user.ROL==="productor" ?  globals.user.CENTRO : globals.CentrosProd[prodInput] ? globals.CentrosProd[prodInput] : []}
                            // options={getUniqueProductorOptions(globals, prodInput)} // Use the deduplication function here
                            // options={globals.user.ROL==="productor" ?  globals.user.CENTRO : Array.from(new Set(globals.CentrosProd[prodInput])) ? Array.from(new Set(globals.CentrosProd[prodInput])) : []}
                            options={
                                globals.user.ROL==="productor" ? globals.user.CENTRO :
                                Array.from(new Set(globals.CentrosProd[prodInput] || []))
                              }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={centroProductorError}
                                    helperText={centroProductorMsg}
                                    label="Centro Productor"
                                    required
                                />
                            )}
                            disablePortal                                 
                        />}
                    </Grid>
                    <Grid item xs={2.265}>
                        <Autocomplete
                            fullWidth
                            filterSelectedOptions
                            value={lerInput}
                            onChange={(event, newValue) => {
                                setLerInput(newValue);
                                setLerError(false);
                                setLerMsg(false);
                            }}
                            options={globals.LERs}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={lerError}
                                    helperText={lerMsg}
                                    label="LER"
                                    required
                                />
                            )}
                            disablePortal                                 
                        />
                    </Grid>
                    <Grid item xs={10.5}>
                        <FormControl fullWidth>
                            <InputLabel>Contrato</InputLabel>
                            <OutlinedInput
                                label="Contrato"
                                readOnly    
                                value={contratoDoc ? contratoDoc.name : ""}
                                onClick={()=>(contratoRef.current.click())}
                                endAdornment={
                                    <InputAdornment>
                                        <IconButton component="label" sx={{pointerEvents:"none"}}>
                                            <input hidden accept=".pdf" ref={contratoRef} type="file" onChange={handleContratoUpdate}/>
                                            <InsertDriveFileIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    {lerInput && globals.LER_Peligrosidad[lerInput] ?
                    <Grid item xs={10.5}>
                    <FormControl fullWidth>
                            <InputLabel>Notificación previa</InputLabel>
                            <OutlinedInput  
                                label="Notificación previa"                    
                                readOnly
                                value={notifDoc ? notifDoc.name : ""}
                                onClick={()=>(notifRef.current.click())}
                                endAdornment={
                                    <InputAdornment>
                                        <IconButton component="label" sx={{pointerEvents:"none"}}>
                                            <input hidden accept=".pdf" ref={notifRef} type="file" onChange={handleNotificacionUpdate}/>
                                            <InsertDriveFileIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    :
                    <></>    
                    }
                    { notifDoc ? 
                    <Grid item xs={5}>
                        <DatePicker 
                            label="Caducidad de la notificación" 
                            format="DD/MM/YYYY" 
                            sx={{width:"100%"}}
                            slotProps={{
                                textField: {
                                  helperText: notifDateMsg,
                                  error: notifDateError
                                },
                            }}
                            disableOpenPicker
                            value={notifDateInput}
                            onChange={(value) => {setNotifDateInput(value); setNotifDateError(false); setNotifDateMsg("")}}
                            required
                        />
                    </Grid>
                    : <></>}
                    { notifDoc ? 
                    <Grid item xs={5} display="flex" alignItems="end" pb={8}>
                        <TextField 
                        fullWidth
                        label="Peso de la notificación" 
                        type={"number"} 
                        inputProps = {{min:0,}} 
                        value={notifWeightInput}
                        error={notifWeightError}
                        helperText={notifWeightMsg}
                        onChange={(event)=>{setNotifWheightInput(event.target.value); setNotifWheightError("false"); setNotifDateMsg("")}}
                        required
                        />
                    </Grid>
                    : <></>}
                    <Grid item xs={10.5} display="flex" justifyContent="end" pt={3}>
                        <Button variant="contained" endIcon={<AddIcon/>} onClick={submitData}>
                            <b>Añadir</b>
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    </Modal>)
}