import { Box, Grid, Modal, Paper, Button, Typography } from "@mui/material";
import React,{useState} from "react";
import { useNavigate } from "react-router-dom";

export const ModalUndo = ({open, onClose, estado, id})=>{
    const navigate = useNavigate();
    const [loadingUndo, setLoadingUndo] = useState(false)

    const handleUndo = () =>{
        async function undoEstado(){
            const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': process.env.REACT_APP_API_KEY,
                },
                body: JSON.stringify({
                    'page':'Undo',
                    'id': id,
                    'estado':estado      
                })

                
            })

            if(response.status !== 200){
                const message = await response.json();
                console.log(message)
                setLoadingUndo(false)
                return
            }          

            setLoadingUndo(false)
            navigate(0)
        }
        setLoadingUndo(true)
        undoEstado()
    }

    const calculateEstado = () => {
        switch (estado){
            case "GESTIONADO":
                return "RECOGIDO";
                break;
            case "RECOGIDO":
                return "PLANIFICADO";
                break;
            case "PLANIFICADO":
                return "PENDIENTE";
                break;
        }
    }

    return(
        <Modal open={open} onClose={onClose} sx={{zIndex:"1500"}}>
            <Box
            sx={{
            position: 'absolute',
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pointerEvents: "none"
            }}>
                <Paper sx={{width: "40vw", minWidth: "600px", display: "flex", alignItems: "center", pointerEvents:"auto"}}>
                    <Grid container display="flex" justifyContent="space-evenly" rowGap={3} mt={5} mb={5}>
                        <Grid item xs={10}>
                            <Typography variant="h5" align="center"><b>¿Estas seguro que quieres volver al estado de {calculateEstado()}?</b></Typography>
                        </Grid>
                        <Grid item xs={3} mt={7}>
                            <Button variant="contained" sx={{backgroundColor:"coral"}} size="large"  onClick={handleUndo} disabled={loadingUndo}>
                                <Typography variant="h5"><b>Sí</b></Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={2} display="flex" justifyContent="end" mt={7}>
                            <Button size="large" onClick={onClose}>
                                <Typography variant="h5">No</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Modal>
    )
}