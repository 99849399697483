import React,{useState} from "react";
import { Box, Button, Grid, Modal, Paper, Typography } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { globals } from "../../data/Globals";

export const ModalCancelation = ({open, onClose, id})=>{
    const navigate = useNavigate();
    const [loadingCancelado, setLoadingCancelado] = useState(false)

    const handleCancelation = () => {
        async function insertCancelation(){
            const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': process.env.REACT_APP_API_KEY,
                },
                body: JSON.stringify({
                    'page':'Cancelado',
                    'token':globals.user.TOKEN,
                    'id': id                
                })
            })

            if(response.status !== 200){
                const message = await response.json();
                console.log(message)
                return
            }          

            setLoadingCancelado(false)
            navigate(0)
        }

        insertCancelation()
    }

    return(
        <Modal open={open} onClose={onClose} sx={{zIndex:"1500"}}>
            <Box
            sx={{
            position: 'absolute',
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pointerEvents: "none"
            }}>
                <Paper sx={{width: "40vw", minWidth: "600px", display: "flex", alignItems: "center", pointerEvents:"auto"}}>
                    <Grid container display="flex" justifyContent="space-evenly" rowGap={3} mt={5} mb={5}>
                        <Grid item xs={10}>
                            <Typography variant="h5" align="center"><b>¿Estas seguro que quieres cancelar esta recogida?</b></Typography>
                        </Grid>
                        <Grid item xs={3} mt={7}>
                            <Button variant="contained" color="error" size="large" endIcon={<DeleteForeverIcon/>} onClick={handleCancelation}>
                                <Typography variant="h5"><b>Sí</b></Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={2} display="flex" justifyContent="end" mt={7}>
                            <Button size="large" onClick={onClose}>
                                <Typography variant="h5">No</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Modal>
    )
}