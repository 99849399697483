import { Autocomplete, InputLabel, OutlinedInput, FormControl, InputAdornment, Box, Button, Checkbox, Container, Grid, Paper, Stack, TextField, Typography, Table, TableContainer, TableHead, TableCell, TableRow, TableBody, TablePagination, IconButton, FormControlLabel } from "@mui/material";
import React, {useState, useEffect, useRef} from "react";
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { globals } from "../../data/Globals";
import { useNavigate } from "react-router-dom";
import PhotoIcon from '@mui/icons-material/Photo';
import { MD5 } from "crypto-js";
import { UploadToBlob } from "../../components/blob/blob_storage";
import dayjs from "dayjs";

const Administracion = () =>{
    const navigate = useNavigate();

    const [pageL, setPageL] = useState(0);
    const [rowsPerPageL, setRowsPerPageL] = useState(10);

    const [pageC, setPageC] = useState(0);
    const [rowsPerPageC, setRowsPerPageC] = useState(10);

    const [pageU, setPageU] = useState(0);
    const [rowsPerPageU, setRowsPerPageU] = useState(10);

    const handleChangePageL = (event, newPage) => {
        setPageL(newPage);
    };

    const handleChangeRowsPerPageL = (event) => {
        setRowsPerPageL(+event.target.value);
        setPageL(0);
    };

    const handleChangePageC = (event, newPage) => {
        setPageC(newPage);
    };

    const handleChangeRowsPerPageC = (event) => {
        setRowsPerPageC(+event.target.value);
        setPageC(0);
    };

    const handleChangePageU = (event, newPage) => {
        setPageU(newPage);
    };

    const handleChangeRowsPerPageU = (event) => {
        setRowsPerPageU(+event.target.value);
        setPageC(0);
    };

    function _capitalize(str){
        let result = str.trim()
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    const [loadedLERs, setLoadedLERs] = useState(false)
    const [loadedCentros, setLoadedCentros] = useState(false)
    const [loadedUsuarios, setLoadedUsuarios] = useState(false)

    useEffect(()=>{
        async function fetchLER(){
          const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
            method:'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': process.env.REACT_APP_API_KEY,
            },
            body: JSON.stringify({
                'page':'LER'
            })
          })
    
          if(response.status !== 200){
            const message = await response.json();
            console.log(message)
            return
          }
    
          const LER_arr = await response.json();

          globals.LERs = []
    
          LER_arr.reverse().forEach(element => {
            globals.LERs.push(element.DESC_LER +" - "+ element.COD_LER)
            globals.LER_Peligrosidad[element.DESC_LER +" - "+ element.COD_LER] = element.FLAG_PELIGROSIDAD
          });

          setLoadedLERs(true)
        }

        async function fetchCentros(){
            const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
              method:'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'authorization': process.env.REACT_APP_API_KEY,
              },
              body: JSON.stringify({
                  'page':'Centro'
              })
            })
      
            if(response.status !== 200){
              const message = await response.json();
              console.log(message)
              return
            }
      
            const gest_arr = await response.json();
      
            gest_arr.reverse().forEach(element => {
                
                globals.Centros.push(element)
                  
            });
            setLoadedCentros(true)
        }

        async function fetchUsuarios(){
            const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
              method:'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'authorization': process.env.REACT_APP_API_KEY,
              },
              body: JSON.stringify({
                  'page':'getUsusarios'
              })
            })
      
            if(response.status !== 200){
              const message = await response.json();
              console.log(message)
              return
            }
      
            const user_arr = await response.json();
      
            user_arr.reverse().forEach(element => {
                
                globals.Usuarios.push(element)
                  
            });
            setLoadedUsuarios(true)
        }
        
        const token = localStorage.getItem("ekogest_user_token");
        if (token !== process.env.REACT_APP_ADMIN_TOKEN){
            navigate("/");
        }
        
        fetchLER()
        fetchCentros()
        fetchUsuarios()
    },[])

    

    const [descLER, setDescLER] = useState();
    const [codLER, setCodLER] = useState();
    const [pelLER, setPelLER] = useState(false);
    const [descLERError, setDescLERError] = useState(false);
    const [codLERError, setCodLERError] = useState(false);
    const [descLERMsg, setDescLERMsg] = useState("");
    const [codLERMsg, setCodLERMsg] = useState("");

    const handleInsertLER = () => {
        async function insertLER(){
            const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': process.env.REACT_APP_API_KEY,
                },
                body: JSON.stringify({
                    'page':'InsertLER',
                    'desc_ler':_capitalize(descLER),
                    'cod_ler':codLER,
                    'peligrosidad':pelLER
                })
            })

            if(response.status !== 200){
                const message = await response.json();
                console.log(message)
                return
            }

            navigate(0)
        }

        let error = false

        if(!descLER){
            error = true
            setDescLERError(true)
            setDescLERMsg("Campo obligatorio")
        }
        if(!codLER){
            error = true
            setCodLERError(true)
            setCodLERMsg("Campo obligatorio")
        }

        if(!error){
            insertLER()
        }
    }

    function handleDeleteLER (lerString) {
        async function deleteLER(){
            const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': process.env.REACT_APP_API_KEY,
                },
                body: JSON.stringify({
                    'page':'DeleteLER',
                    'desc_ler': lerString.split(" - ")[0],
                    'cod_ler': lerString.split(" - ").splice(1,lerString.split(" - ").length-1).toString(),
                    'peligrosidad': globals.LER_Peligrosidad[lerString]
                })
            })

            if(response.status !== 200){
                const message = await response.json();
                console.log(message)
                return
            }

            navigate(0)
        }
        deleteLER()
    }

    const [descCentro,setDescCentro] = useState()
    const [codCentro, setCodCentro] = useState()
    const [propCentro, setPropCentro] = useState()
    const [tipoCentro, setTipoCentro] = useState()
    const [descCentroError,setDescCentroError] = useState(false)
    const [codCentroError, setCodCentroError] = useState(false)
    const [propCentroError, setPropCentroError] = useState(false)
    const [tipoCentroError, setTipoCentroError] = useState(false)
    const [descCentroMsg,setDescCentroMsg] = useState("")
    const [codCentroMsg, setCodCentroMsg] = useState("")
    const [propCentroMsg, setPropCentroMsg] = useState("")
    const [tipoCentroMsg, setTipoCentroMsg] = useState("")

    const handleInsertCentro = () => {
        async function insertCentro(){
            const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': process.env.REACT_APP_API_KEY,
                },
                body: JSON.stringify({
                    'page':'InsertCentro',
                    'desc_centro':_capitalize(descCentro),
                    'cod_centro':codCentro,
                    'propietario':_capitalize(propCentro),
                    'tipo':tipoCentro.toLowerCase()
                })
            })

            if(response.status !== 200){
                const message = await response.json();
                console.log(message)
                return
            }

            navigate(0)
        }

        let error = false

        if(!descCentro){
            error = true
            setDescCentroError(true)
            setDescCentroMsg("Campo obligatorio")
        }
        if(!codCentro){
            error = true
            setCodCentroError(true)
            setCodCentroMsg("Campo obligatorio")
        }
        if(!propCentro){
            error = true
            setPropCentroError(true)
            setPropCentroMsg("Campo obligatorio")
        }
        if(!tipoCentro){
            error = true
            setTipoCentroError(true)
            setTipoCentroMsg("Campo obligatorio")
        }

        if(!error){
            insertCentro()
        }
    }

    function handleDeleteCentro (centro) {
        async function deleteCentro(){
            const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': process.env.REACT_APP_API_KEY,
                },
                body: JSON.stringify({
                    'page':'DeleteCentro',
                    'centro': centro.CENTRO,
                    'propietario': centro.PROPIETARIO,
                    'tipo': centro.TIPO
                })
            })

            if(response.status !== 200){
                const message = await response.json();
                console.log(message)
                return
            }

            navigate(0)
        }
        deleteCentro()
    }

    const avatarRef = useRef(null);

    const handleUpdateAvatar = (event) => {
        setAvatar(event.target.files[0]);
    }

    const [usuario, setUsuario] = useState()
    const [correo, setCorreo] = useState()
    const [rol, setRol] = useState()
    const [centro, setCentro] = useState()
    const [pasw, setPasw] = useState()
    const [paswR, setPaswR] = useState()
    const [dia, setDia] = useState()
    const [avatar, setAvatar] = useState()

    const [usuarioError, setUsuarioError] = useState(false)
    const [correoError, setCorreoError] = useState(false)
    const [rolError, setRolError] = useState(false)
    const [centroError, setCentroError] = useState(false)
    const [paswError, setPaswError] = useState(false)
    const [paswRError, setPaswRError] = useState(false)
    const [diaError, setDiaError] = useState(false)
    const [avatarError, setAvatarError] = useState(false)

    const [usuarioMsg, setUsuarioMsg] = useState("")
    const [correoMsg, setCorreoMsg] = useState("")
    const [rolMsg, setRolMsg] = useState("")
    const [centroMsg, setCentroMsg] = useState("")
    const [paswMsg, setPaswMsg] = useState("")
    const [paswRMsg, setPaswRMsg] = useState("")
    const [diaMsg, setDiaMsg] = useState("")
    const [avatarMsg, setAvatarMsg] = useState("")

    const handleInsertUsuario = () => {
        async function insertUsuario(_avatarName, _dia){
            const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': process.env.REACT_APP_API_KEY,
                },
                body: JSON.stringify({
                    'page':'InsertUsuario',
                    'usuario':usuario,
                    'correo':correo,
                    'rol':rol.toLowerCase(),
                    'centro':centro,
                    'password':MD5(pasw),
                    'dia':_dia,
                    'avatar':_avatarName,
                    'token': MD5(new Date().getTime()+685).toString()
                })
            })

            if(response.status !== 200){
                const message = await response.json();
                console.log(message)
                return
            }

            navigate(0)
        }

        let error = false

        if(!usuario){
            error = true
            setUsuarioError(true)
            setUsuarioMsg("Campo obligatorio")
        }
        if(!correo){
            error = true
            setCorreoError(true)
            setCorreoMsg("Campo obligatorio")
        }
        if(!rol){
            error = true
            setRolError(true)
            setRolMsg("Campo obligatorio")
        }
        if(!centro){
            error = true
            setCentroError(true)
            setCentroMsg("Campo obligatorio")
        }
        if(!pasw){
            error = true
            setPaswError(true)
            setPaswMsg("Campo obligatorio")
        }
        if(!paswR){
            error = true
            setPaswRError(true)
            setPaswRMsg("Campo obligatorio")
        }
        if(!dia){
            error = true
            setDiaError(true)
            setDiaMsg("Campo obligatorio")
        }
        if(!avatar){
            error = true
            setAvatarError(true)
            setAvatarMsg("Campo obligatorio")
        }
        if(pasw !== paswR){
            error = true
            setPaswError(true)
            setPaswRError(true)
            setPaswMsg("Las contraseñas no coinciden")
            setPaswRMsg("Las contraseñas no coinciden")
        }

        if(!error){

            let avatarName = "";

            if(avatar){
                // const token = MD5(new Date().getTime()).toString()
                const token = MD5(usuario).toString()
                avatarName = avatar.name.replace(".png",token+".png");

                UploadToBlob(avatar, avatarName);
            }

            let diaSemana = 0

            switch(dia){
                case "LUNES":
                    diaSemana = 1;
                    break;
                case "MARTES":
                    diaSemana = 2;
                    break;
                case "MIÉRCOLES":
                    diaSemana = 3;
                    break;
                case "JUEVES":
                    diaSemana = 4;
                    break;
                case "VIERNES":
                    diaSemana = 5;
                    break;
                case "SÁBADO":
                    diaSemana = 6;
                    break;  
                case "DOMINGO":
                    diaSemana = 7;
                    break;
            }

            insertUsuario(avatarName, diaSemana)
        }
    }

    function handleDeleteUsuario (usuario) {
        async function deleteUsuario(){

            let user_token = localStorage.getItem("ekogest_user_token");

            const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': process.env.REACT_APP_API_KEY,
                },
                body: JSON.stringify({
                    'page':'DeleteUsuario',
                    'token':user_token,
                    'usuario': usuario.USUARIO,
                    'rol': usuario.ROL.toLowerCase(),
                    'centro': usuario.CENTRO,
                    'entidad': usuario.ENTIDAD
                })
            })

            if(response.status !== 200){
                const message = await response.json();
                console.log(message)
                return
            }

            navigate(0)
        }
        deleteUsuario()
    }

    return(
        <Box width="90%">
            <Stack spacing={2}>
                <Typography variant="h6" pt={6}>
                    <b>Añadir LER</b>
                </Typography>
                <Paper elevation={3}>
                    <Grid container display="flex" justifyContent="space-between" alignItems="center" m={3}>
                        <Grid item xs={3}>
                            <TextField
                            fullWidth
                            label="Descripción del residuo"
                            value={descLER}
                            error={descLERError}
                            helperText={descLERMsg}
                            onChange={(event)=>{setDescLER(event.target.value)}}
                            required
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                            fullWidth
                            label="Código LER"
                            value={codLER}
                            error={codLERError}
                            helperText={codLERMsg}
                            onChange={(event)=>{setCodLER(event.target.value)}}
                            required
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                            control={<Checkbox checked={pelLER} onChange={(event)=>{setPelLER(event.target.checked)}}/>}
                            label="Residuo peligroso"
                            />
                        </Grid>
                        <Grid item xs={2} display="flex">
                            <Button
                            variant="contained"
                            endIcon={<AddIcon/>}
                            size="large"
                            onClick={handleInsertLER}
                            >
                               <b>Añadir</b>
                            </Button>
                        </Grid>
                        <Grid item xs={12} mt={6}>
                            {loadedLERs ?
                            <TableContainer sx={{maxWidth:"97%"}}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow >
                                            {["DESCRIPCIÓN", "CÓDIGO", "PELIGROSIDAD"].map((column)=>(
                                                <TableCell
                                                key={column}
                                                align="center"
                                                sx={{backgroundColor:"#8cba37"}}>
                                                    {column}
                                                </TableCell>
                                            ))}
                                            <TableCell sx={{backgroundColor:"#8cba37"}}/>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {globals.LERs.slice(pageL*rowsPerPageL, pageL*rowsPerPageL+rowsPerPageL).map((row) =>(
                                            <TableRow>
                                                <TableCell align="center">
                                                    {row.split(" - ")[0]}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {row.split(" - ").splice(1,row.split(" - ").length-1).toString()}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {globals.LER_Peligrosidad[row] ? "SÍ" : "NO"}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {<IconButton color="error" onClick={()=>{handleDeleteLER(row)}}>
                                                        <DeleteForeverIcon/>
                                                    </IconButton>}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            :
                            <></>}
                            {loadedLERs ?
                            <TablePagination
                            sx={{maxWidth:"97%"}}
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={globals.LERs.length}
                            rowsPerPage={rowsPerPageL}
                            page={pageL}
                            onPageChange={handleChangePageL}
                            onRowsPerPageChange={handleChangeRowsPerPageL}
                            labelRowsPerPage="Resultados por pagina:"
                            labelDisplayedRows={
                            ({ from, to, count })=>{
                                return '' + from + '-' + to + ' de ' + count
                            }
                            }
                            />
                            :
                            <></>}
                        </Grid>
                    </Grid>
                </Paper>
                <Typography variant="h6" pt={10}>
                    <b>Añadir Centro</b>
                </Typography>
                <Paper elevation={3}>
                    <Grid container display="flex" justifyContent="space-between" alignItems="center" m={3}>
                        <Grid item xs={3}>
                            <TextField
                            fullWidth
                            label="Descripción del centro"
                            value={descCentro}
                            error={descCentroError}
                            helperText={descCentroMsg}
                            onChange={(event)=>{setDescCentro(event.target.value)}}
                            required
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                            fullWidth
                            label="Código del centro"
                            value={codCentro}
                            error={codCentroError}
                            helperText={codCentroMsg}
                            onChange={(event)=>{setCodCentro(event.target.value)}}
                            required
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                            fullWidth
                            label="Entidad del centro"
                            value={propCentro}
                            error={propCentroError}
                            helperText={propCentroMsg}
                            onChange={(event)=>{setPropCentro(event.target.value)}}
                            required
                            />
                        </Grid>
                        <Grid item xs={1.5}>
                            <Autocomplete
                            fullWidth
                            disablePortal
                            value={tipoCentro}
                            options={["GESTOR","PRODUCTOR"]}
                            onChange={(event, newValue) => {
                                setTipoCentro(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                required
                                error={tipoCentroError}
                                helperText={tipoCentroMsg}
                                label="Tipo de centro"
                                />
                            )}
                            />
                        </Grid>
                        <Grid item xs={2} display="flex">
                            <Button
                            variant="contained"
                            endIcon={<AddIcon/>}
                            size="large"
                            onClick={handleInsertCentro}
                            >
                                <b>Añadir</b>
                            </Button>
                        </Grid>
                        <Grid item xs={12} mt={6}>
                            {loadedCentros ?
                            <TableContainer sx={{maxWidth:"97%"}}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow >
                                            {["DESCRIPCIÓN", "CÓDIGO", "ENTIDAD", "TIPO"].map((column)=>(
                                                <TableCell
                                                key={column}
                                                align="center"
                                                sx={{backgroundColor:"#8cba37"}}>
                                                    {column}
                                                </TableCell>
                                            ))}
                                            <TableCell sx={{backgroundColor:"#8cba37"}}/>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {globals.Centros.slice(pageC*rowsPerPageC, pageC*rowsPerPageC+rowsPerPageC).map((row) =>(
                                            <TableRow>
                                                <TableCell align="center">
                                                    {row.CENTRO.split(" - ")[0]}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {row.CENTRO.split(" - ").splice(1,row.CENTRO.split(" - ").length-1).toString()}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {row.PROPIETARIO}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {row.TIPO.toUpperCase()}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {<IconButton color="error" onClick={()=>{handleDeleteCentro(row)}}>
                                                        <DeleteForeverIcon/>
                                                    </IconButton>}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            :
                            <></>}
                            {loadedCentros ?
                            <TablePagination
                            sx={{maxWidth:"97%"}}
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={globals.Centros.length}
                            rowsPerPage={rowsPerPageC}
                            page={pageC}
                            onPageChange={handleChangePageC}
                            onRowsPerPageChange={handleChangeRowsPerPageC}
                            labelRowsPerPage="Resultados por pagina:"
                            labelDisplayedRows={
                            ({ from, to, count })=>{
                                return '' + from + '-' + to + ' de ' + count
                            }
                            }
                            />
                            :
                            <></>}
                        </Grid>
                    </Grid>
                </Paper>
                <Typography variant="h6" pt={10}>
                    <b>Añadir Usuario</b>
                </Typography>
                <Paper elevation={3}>
                    <Grid container display="flex" justifyContent="space-between" alignItems="center" m={3}>
                        <Grid item xs={2.5}>
                            <Stack gap={3}>
                                <TextField
                                fullWidth
                                label="Usuario"
                                value={usuario}
                                error={usuarioError}
                                helperText={usuarioMsg}
                                onChange={(event)=>{setUsuario(event.target.value)}}
                                required
                                />
                                <TextField
                                fullWidth
                                label="Contraseña"
                                value={pasw}
                                error={paswError}
                                helperText={paswMsg}
                                onChange={(event)=>{setPasw(event.target.value)}}
                                required
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Stack gap={3}>
                                <TextField
                                fullWidth
                                label="Correo electrónico"
                                value={correo}
                                error={correoError}
                                helperText={correoMsg}
                                onChange={(event)=>{setCorreo(event.target.value)}}
                                required
                                />
                                <TextField
                                fullWidth
                                label="Repetir contraseña"
                                value={paswR}
                                error={paswRError}
                                helperText={paswRMsg}
                                onChange={(event)=>{setPaswR(event.target.value)}}
                                required
                                />
                            </Stack>
                        </Grid>                        
                        <Grid item xs={2.5}>
                            <Stack gap={3}>
                                <Autocomplete
                                fullWidth
                                disablePortal
                                value={rol}
                                options={["GESTOR","PRODUCTOR","ADMINISTRADOR"]}
                                onChange={(event, newValue) => {
                                    setRol(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    required
                                    error={rolError}
                                    helperText={rolMsg}
                                    label="Rol"
                                    />
                                )}
                                />
                                <Autocomplete
                                fullWidth
                                disablePortal
                                value={dia}
                                options={["LUNES","MARTES","MIÉRCOLES","JUEVES","VIERNES","SÁBADO","DOMINGO"]}
                                onChange={(event, newValue) => {
                                    
                                }}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    required
                                    error={diaError}
                                    helperText={diaMsg}
                                    label="Día habitual de recogida"
                                    />
                                )}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Stack gap={3}>
                            <Autocomplete
                                fullWidth
                                disablePortal
                                value={centro}
                                options={globals.Centros.map(element => element.CENTRO)}
                                onChange={(event, newValue) => {
                                    setCentro(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    required
                                    error={centroError}
                                    helperText={centroMsg}
                                    label="Centro asignado"
                                    />
                                )}
                                />
                                <FormControl fullWidth>
                                    <InputLabel>Avatar</InputLabel>
                                    <OutlinedInput
                                        label="Avatar"
                                        readOnly    
                                        value={avatar ? avatar.name : ""}
                                        onClick={()=>(avatarRef.current.click())}
                                        endAdornment={
                                            <InputAdornment>
                                                <IconButton component="label" sx={{pointerEvents:"none"}}>
                                                    <input hidden accept=".png" ref={avatarRef}  type="file" onChange={handleUpdateAvatar}/>
                                                    <PhotoIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Stack>
                        </Grid>
                        <Grid item xs={1.5} display="flex">
                            <Button
                            variant="contained"
                            endIcon={<AddIcon/>}
                            size="large"
                            onClick={handleInsertUsuario}
                            >
                                <b>Añadir</b>
                            </Button>
                        </Grid>                        
                        <Grid item xs={12} mt={6}>
                            {loadedUsuarios ?
                            <TableContainer sx={{maxWidth:"97%"}}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow >
                                            {["USUARIO", "ROL", "CENTRO", "ENTIDAD"].map((column)=>(
                                                <TableCell
                                                key={column}
                                                align="center"
                                                sx={{backgroundColor:"#8cba37"}}>
                                                    {column}
                                                </TableCell>
                                            ))}
                                            <TableCell sx={{backgroundColor:"#8cba37"}}/>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {globals.Usuarios.slice(pageU*rowsPerPageU, pageU*rowsPerPageU+rowsPerPageU).map((row) =>(
                                            <TableRow>
                                                <TableCell align="center">
                                                    {row.USUARIO}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {row.ROL ? row.ROL.toUpperCase() : ""}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {row.CENTRO}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {row.PROPIETARIO}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {<IconButton color="error" onClick={()=>{handleDeleteUsuario(row)}}>
                                                        <DeleteForeverIcon/>
                                                    </IconButton>}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            :
                            <></>}
                            {loadedUsuarios ?
                            <TablePagination
                            sx={{maxWidth:"97%"}}
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={globals.Usuarios.length}
                            rowsPerPage={rowsPerPageU}
                            page={pageU}
                            onPageChange={handleChangePageU}
                            onRowsPerPageChange={handleChangeRowsPerPageU}
                            labelRowsPerPage="Resultados por pagina:"
                            labelDisplayedRows={
                            ({ from, to, count })=>{
                                return '' + from + '-' + to + ' de ' + count
                            }
                            }
                            />
                            :
                            <></>}
                        </Grid>
                    </Grid>
                </Paper>
            </Stack>
        </Box>
    )
}

export default Administracion