import { Grid, Typography, Stack, Paper, Checkbox, FormControlLabel, Button, TextField, Autocomplete } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers";
import SendIcon from '@mui/icons-material/Send';
import dayjs from "dayjs";
import { useState, useEffect } from 'react';
import { globals } from "../../data/Globals";
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

const Solicitud = () => {
    const navigate = useNavigate();

    const [valueGestor, setGestor] = useState();
    const [valueCentro, setCentro] = useState();
    const [valueLER, setLER] = useState();
    const [valueTipoCont, setTipoCont] = useState();
    const [valueNumCont, setNumCont] = useState(0);
    const [recogerCont, setRecogida] = useState(false);
    const [valueNumRec, setNumRec] = useState(0);
    const [valueFecha, setFecha] = useState();

    const [inputGestor, setInputGestor] = useState('');
    const [inputCentro, setInputCentro] = useState('');
    const [inputLER, setInputLER] = useState('');
    const [inputTipoCont, setInputTipoCont] = useState('');

    const [resetKey, setResetKey] = useState(0);

    const [errorGestor, setErrorGestor] = useState(false);
    const [errorCentro, setErrorCentro] = useState(false);
    const [errorLER, setErrorLER] = useState(false);
    const [errorTipoCont, setErrorTipoCont] = useState(false);
    const [errorNumCont, setErrorNumCont] = useState(false);
    const [errorNumRec, setErrorNumRec] = useState(false);

    const [msgGestor, setMsgGestor] = useState("");
    const [msgCentro, setMsgCentro] = useState("");
    const [msgLER, setMsgLER] = useState("");
    const [msgTipoCont, setMsgTipoCont] = useState("");
    const [msgNumCont, setMsgNumCont] = useState("");
    const [msgNumRec, setMsgNumRec] = useState("");

    const [formOptions, setFormOptions] = useState([""]);
    const [gestorOptions, setGestorOptions] = useState([""]);
    const [centroOptions, setCentroOptions] = useState([""]);
    const [LerOptions, setLerOptions] = useState([""]);

    const [loadingSubmit, setLoadingSubmit] = useState(false);

    useEffect(()=>{
        let user_token = localStorage.getItem("ekogest_user_token");

        async function fetchFormData(){
            const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': process.env.REACT_APP_API_KEY,
                },
                body: JSON.stringify({
                    'page':'solicitude',
                    'token': user_token,
                })
            })
      
            if(response.status !== 200){
                const message = await response.json();
                console.log(message)
                return
            }
      
            const form_object = await response.json();
      
            globals.formData = form_object

            setResetKey((value)=>value+1)
        }

        if(user_token !== null){
            fetchFormData()
        }
    },[])

    const submitData = () => {
        async function insertRecogida(){
            const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': process.env.REACT_APP_API_KEY,
                },
                body: JSON.stringify({
                    'page':'Submit',
                    'token':globals.user.TOKEN,
                    'gestor': valueGestor,
                    'centro_gest': valueCentro,
                    'productor': globals.user.PROPIETARIO,
                    'centro_prod': globals.user.CENTRO,
                    'LER': valueLER,
                    'tipoContenedores': valueTipoCont,
                    'numContenedores': parseInt(valueNumCont),
                    'numSubstituidos': parseInt(valueNumRec),
                    'fechaRecogida': valueFecha.format("DD-MM-YYYY")
                })
            })

            setLoadingSubmit(false)
      
            if(response.status !== 200){
                const message = await response.json();
                console.log(message)
                return
            }          

            navigate("../seguimiento")
            
        }

        function clearData(){
            setGestor("");
            setCentro("");
            setLER("");
            setTipoCont("");
            setNumCont(0);
            setRecogida(false);
            setNumRec(0);
            setFecha(dayjs().day(globals.user.DIA_SEMANA).add(7, 'day'));
            setResetKey((prevKey)=>prevKey+1);
        }

        setLoadingSubmit(true);
        insertRecogida()     
        clearData()   
    }

    useEffect(()=>{
        if(valueFecha === undefined){
            setFecha(dayjs().day(globals.user.DIA_SEMANA).add(7, 'day'))
        }

        if (globals.user.ROL !== "productor"){
            navigate("/");
            console.log("CAGASTE")
        }
    })

    const handleSubmit = () => {
        let error = false;

        if(!valueGestor){
            error = true;
            setErrorGestor(true);
            setMsgGestor("Campo obligatório");
        }
        if(!valueCentro){
            error = true;
            setErrorCentro(true);
            setMsgCentro("Campo obligatório");
        }
        if(!valueLER){
            error = true;
            setErrorLER(true);
            setMsgLER("Campo obligatório");
        }
        if(!valueTipoCont){
            error = true;
            setErrorTipoCont(true);
            setMsgTipoCont("Campo obligatório");
        }
        if(!valueNumCont && valueTipoCont !== "Sin contenedor"){
            error = true;
            setErrorNumCont(true);
            setMsgNumCont("Campo obligatório");
        }
        if(valueNumCont < 0 && valueTipoCont !== "Sin contenedor"){
            error = true;
            setErrorNumCont(true);
            setMsgNumCont("Introduce un valor válido");
        }
        if(valueNumRec < 0){
            error = true;
            setErrorNumRec(true);
            setMsgNumRec("Introduce un valor válido");
        }

        if(!error){
            submitData()
        }

    }

    useEffect(()=>{
        let _r = []
        for(let i=0; i<globals.formData.length; i++){
            _r.push(globals.formData[i].GESTOR)
        }
        const _dr = [...new Set(_r)];
        setGestorOptions(_dr);
    },[globals.formData])

    useEffect(()=>{
        if(valueCentro){
            let _r = []

            for(let i=0; i<globals.formData.length; i++){
                if(globals.formData[i].GESTOR===valueGestor && globals.formData[i].CENTRO_GESTOR===valueCentro){
                    _r.push(globals.formData[i].DESC_LER)
                }
            }

            const _dr = [...new Set(_r)];
            setLerOptions(_dr);
        }
        else{
            let _r = []

            for(let i=0; i<globals.formData.length; i++){
                if(globals.formData[i].GESTOR===valueGestor){
                    _r.push(globals.formData[i].CENTRO_GESTOR)
                }
            }

            const _dr = [...new Set(_r)];
            setCentroOptions(_dr);
        }
    },[valueCentro, valueGestor])

    return (
        <Grid item xs={12}>
            <Stack spacing={5} paddingRight={5} paddingTop={2}>
                {/* BLOQUE CENTROS */}
                <Stack>
                    <Typography variant="h6">
                            <b>Información general</b>
                    </Typography>
                    <Paper elevation={3} sx={{mt:"15px"}}>
                        <Grid container sx={{
                            display:"flex",
                            justifyContent:"space-evenly",
                            marginTop:"20px",
                            marginBottom:"20px",
                        }}>
                            <Grid item xs={3.8}>
                                <Autocomplete
                                key={resetKey}
                                filterSelectedOptions
                                options={(gestorOptions)}                                
                                value={valueGestor}
                                inputValue={inputGestor}
                                onChange={(event, newValue) => {
                                    setGestor(newValue);
                                    setCentro(null);
                                    setInputCentro('');
                                    setLER(null);
                                    setInputLER('');
                                    setErrorGestor(false);
                                    setMsgGestor("");
                                    setResetKey((prevKey)=>prevKey+1)
                                }}
                                onInputChange={(event, newValue) => {
                                    setInputGestor(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label="Gestor"
                                    required
                                    error={errorGestor}
                                    helperText={msgGestor}
                                    />
                                )}
                                />
                            </Grid>
                            <Grid item xs={3.8}>                                
                                <Autocomplete
                                key={resetKey}
                                filterSelectedOptions
                                options={centroOptions}
                                disabled={valueGestor ? false : true}
                                onChange={(event, newValue) => {
                                    setCentro(newValue);
                                    setLER(null);
                                    setErrorCentro(false);
                                    setMsgCentro("");
                                    setResetKey((prevKey)=>prevKey+1)
                                }}
                                onInputChange={(event, newValue) => {
                                    setInputCentro(newValue);
                                }}
                                value={valueCentro}
                                inputValue={inputCentro}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label="Centro Gestor"
                                    required
                                    error={errorCentro}
                                    helperText={msgCentro}                                    
                                    />
                                )}
                                />
                            </Grid>
                            <Grid item xs={3.8}>
                                <Autocomplete
                                key={resetKey}
                                disabled={valueCentro ? false : true}
                                onChange={(event, newValue) => {
                                    setLER(newValue);
                                    setErrorLER(false);
                                    setMsgLER("")
                                }}
                                onInputChange={(event, newValue) => {
                                    setInputLER(newValue);
                                }}
                                value={valueLER}
                                inputValue={inputLER}
                                filterSelectedOptions
                                options={LerOptions}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    required
                                    label="LER"
                                    error={errorLER}
                                    helperText={msgLER}
                                    />
                                )}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Stack> 
                {/* BLOQUE CONTENEDORES */}
                <Stack>
                    <Typography variant="h6">
                        <b>Información sobre contenedores</b>
                    </Typography>
                    <Paper elevation={3} sx={{mt:"15px"}}>
                        <Grid container rowGap={2} sx={{
                            display:"flex",
                            justifyContent:"space-evenly",
                            marginTop:"20px",
                            marginBottom:"20px",
                        }}>
                            <Grid item xs={5.5}>
                                <Autocomplete
                                key={resetKey}
                                options={globals.Contenedores}
                                onChange={(event, newValue) => {
                                    setTipoCont(newValue);
                                    setErrorTipoCont(false);
                                    setMsgTipoCont("");
                                }}
                                onInputChange={(event, newValue) => {
                                    setInputTipoCont(newValue);
                                    if(newValue === "Sin contenedor"){
                                        setNumCont(0)
                                    }
                                }}
                                value={valueTipoCont}
                                inputValue={inputTipoCont}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    required
                                    label="Tipo de contenedores"
                                    error={errorTipoCont}
                                    helperText={msgTipoCont}
                                    />
                                )}
                                />
                            </Grid>
                            <Grid item xs={5.5}>
                                <TextField 
                                fullWidth
                                disabled={inputTipoCont==="Sin contenedor"}
                                label="Nº de contenedores" 
                                type={"number"} 
                                inputProps = {{min:0,}} 
                                required
                                onChange={(event)=>{
                                    setNumCont(event.target.value);
                                    setErrorNumCont(false);
                                    setMsgNumCont("");
                                }}
                                value={valueNumCont}
                                error={errorNumCont}
                                helperText={msgNumCont}
                                />
                            </Grid>
                            <Grid item xs={5.5}>
                                <FormControlLabel
                                key={resetKey}
                                label="Substituir Contenedores de residuos"
                                control={<Checkbox/>}
                                value={recogerCont}
                                onChange={(event,newValue)=>{
                                    setRecogida(newValue);
                                    newValue ? setNumRec(valueNumRec) : setNumRec(0);                                    
                                }}
                                sx={{marginTop:"15px"}}
                                />
                            </Grid>
                            <Grid item xs={5.5}>
                                <TextField 
                                fullWidth
                                disabled={recogerCont ? false : true}
                                label="Contenedores a substituir" 
                                type={"number"} 
                                inputProps = {{min:0,}}
                                defaultValue = {0}
                                onChange = {(event)=>{
                                    setNumRec(event.target.value);
                                    setErrorNumRec(false);
                                    setMsgNumRec("");
                                }}
                                value = {valueNumRec}
                                error={errorNumRec}
                                helperText={msgNumRec}
                                />
                            </Grid>
                            <Grid item xs={5.5}/>
                        </Grid>
                    </Paper>
                </Stack>
                {/* BLOQUE FECHA */}
                <Stack>
                <Typography variant="h6">
                    <b>Fecha de recogida</b>
                </Typography>
                <Paper elevation={3} sx={{mt:"15px"}}>
                    <Grid container>
                        <Grid item xs={3.5} minWidth={300}>
                            <StaticDatePicker
                                displayStaticWrapperAs="desktop"
                                slotProps={{
                                    toolbar: { toolbarFormat: 'ddd DD MMMM', hidden: false },
                                }}
                                value={valueFecha}
                                displayWeekNumber 
                                onChange={(value) => {setFecha(value)}}
                                dayOfWeekFormatter={(day) => `${day}.`}
                            />
                        </Grid>
                        <Grid item xs={8.5} display="flex" justifyContent="center">
                            <Stack spacing={2} fullWidth display="flex" justifyContent="center"> 
                            <Typography alignSelf="start" display="flex">
                            En este calendario se debe seleccionar la fecha en la que se solicita la recogida.<br/>
                            Por defecto, se preselecciona el siguiente día laboral habitual.<br/>
                            </Typography>
                            <Typography alignSelf="start" display="flex">
                            <CircleOutlinedIcon/>  Fecha actual<br/>
                            </Typography>
                            <Typography alignSelf="start" display="flex">
                            <CircleIcon color="primary"/>  Fecha recogida seleccionada<br/>
                            </Typography>
                            <Typography alignSelf="start" display="flex">
                            La fecha solicitada puede no ser la fecha en la que se realice la recogida.<br/>
                            El gestor indicará si hay algún cambio en la fecha de recogida, y dicho cambio se notificará mediante e-mail.
                            </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Paper>
                </Stack>
                <Grid container>
                    <Grid item xs={2}>
                        {loadingSubmit ?
                        <LoadingButton loading loadingPosition="end" endIcon={<SendIcon/>} variant="contained">
                            Solicitar
                        </LoadingButton>
                        :
                        <Button variant="contained" color="secondary" endIcon={<SendIcon/>} onClick={handleSubmit} size="large">
                            Solicitar
                        </Button>
                        }
                    </Grid>
                </Grid>
            </Stack>
        </Grid>
    )
}

export default Solicitud
