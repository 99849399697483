import React, { useState } from "react";
import Grid from '@mui/material/Grid';
import { Paper, TextField, IconButton, InputAdornment, FilledInput, InputLabel, FormControl, Button, FormHelperText } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoginIcon from '@mui/icons-material/Login';
import { useNavigate } from "react-router-dom";
import { globals } from "../../data/Globals";
import { MD5 } from "crypto-js";
import backgroundLogin from "../../images/EkoLogin.png";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';

const Login = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [user, setUser] = useState("");
    const [pass, setPass] = useState("");
    const [userError, setUserError] = useState("");
    const [passError, setPassError] = useState("");
    const [loadingButt, setLoadingButt] = useState(false);

    const theme = createTheme({
        palette:{
          primary:{
            main:"#8cba37",
          },
          secondary:{
             main:"#2b6244",
          },
        },
    })

    async function check_user(_user, _pass){
        const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
            method:'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': process.env.REACT_APP_API_KEY,
            },
            body: JSON.stringify({
                'page':'login',
                'user':_user,
                'pass':_pass,
            })
        })

        if(response.status === 500){
            console.log(response.body)
            setPass("")
            setPassError("Credenciales incorrectas")
            setLoadingButt(false)
            return
        }

        globals.user = await response.json();
        setLoadingButt(false)

        if(globals.user.TOKEN){
            localStorage.setItem("ekogest_user_token", globals.user.TOKEN);
            navigate("/");
        }
    }


    return(
        <ThemeProvider theme={theme}>
            <Grid container alignItems="center" justifyContent="center" sx={{backgroundImage: `url(${backgroundLogin})`,backgroundRepeat: "no-repeat",backgroundPosition: "center", backgroundSize:"cover", height:"100vh"}}>
                <Grid item xs={8}>
                    <Paper>
                        <Grid container justifyContent="center" alignItems="center" rowGap={2} columnGap={2} pb={5} pt={5}>
                            <Grid item xs={7}>
                                <TextField fullWidth label="Usuario" required variant="filled"
                                error={userError ? true:false}
                                helperText={userError}
                                onChange={(event)=>{
                                    setUser(event.target.value)
                                    setUserError(false);
                                }}
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <FormControl fullWidth variant="filled" required>
                                    <InputLabel>Contraseña</InputLabel>
                                    <FilledInput label="Contraseña" type={showPassword ? "text" : "password"} required
                                    error={passError ? true:false}
                                    value={pass}
                                    onChange={(event)=>{
                                        setPass(event.target.value);
                                        setPassError(false);
                                    }}
                                    endAdornment={
                                        <InputAdornment>
                                            <IconButton onClick={()=>setShowPassword(!showPassword)}>
                                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    />
                                    <FormHelperText error={passError ? true:false}>{passError}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={7} mt={4}>
                                {loadingButt?
                                <LoadingButton variant="contained" size="large" loading loadingPosition="end" endIcon={<LoginIcon/>}>
                                    INICIAR SESIÓN
                                </LoadingButton>
                                :
                                <Button  variant="contained" endIcon={<LoginIcon/>} size="large"
                                onClick={()=>{
                                    setLoadingButt(true)
                                    let error = false;
                                    if(!user){
                                        setUserError("Introduce tu nombre de usuario");
                                        error = true;
                                    }
                                    if(!pass){
                                        setPassError("Introduce tu contraseña");
                                        error = true;
                                    }
                                    if(error){
                                        setLoadingButt(false)
                                        return
                                    };

                                    check_user(user.toLowerCase(), MD5(pass).toString());
                                }
                                }
                                >
                                    INICIAR SESIÓN
                                </Button>
                                }
                            </Grid>
                        </Grid>
                    </Paper>             
                </Grid>           
            </Grid>
        </ThemeProvider>
    )
}

export default Login;