import React, { useState,useEffect } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box, CircularProgress, Chip } from "@mui/material";
import { columns } from '../../components/tabla-seguimientos/columns';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormPendiente from "../../components/dropdown_forms/FormPendiente";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { globals } from "../../data/Globals";
import FormCancelacion from "../../components/dropdown_forms/FormCancelacion";
import { FormPlanificadoGestor } from "../../components/dropdown_forms/FormPlanificadoGestor";
import { FormPlanificadoProductor } from "../../components/dropdown_forms/FormPlanificadoProductor";
import { FormRecogidoGestor } from "../../components/dropdown_forms/FormRecogidoGestor";
import { FormRecogidoProductor } from "../../components/dropdown_forms/FormRecogidoProductor";
import { FormGestionadoGestor } from "../../components/dropdown_forms/FormGestionadoGestor";
import { FormGestionadoProductor } from "../../components/dropdown_forms/FormGstionadoProductor";

function Row(rowData) {
  const row = rowData;
  const [open, setOpen] = useState(false);

  const estadoPip = <Chip label={row.rowData.ESTADO} sx={row.rowData.ESTADO==="PENDIENTE" ? {backgroundColor:"#C2D0D8"} : row.rowData.ESTADO==="PLANIFICADO" ? {backgroundColor:"#d7d8c2"} : row.rowData.ESTADO==="GESTIONADO" ? {backgroundColor:"#c2d8c3"} : row.rowData.ESTADO==="CANCELADO" ? {backgroundColor:"#ffa3a3"} : {backgroundColor:"#dbdbdb"}}/>
  row.rowData._ESTADO = estadoPip
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset'}}} hover>
      <TableCell>
        <IconButton
          aria-label="expand row"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
      {columns.map((column) => {
        const value = row.rowData[column.id];
        return (
          <TableCell 
          key={column.id}
          align={column.align}
          >
            {column.format && typeof value === 'number'
              ? column.format(value)
              : value}
          </TableCell>
        );
      })}
      </TableRow>
      <TableRow >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Container maxWidth>
              {row.rowData.ESTADO==="PENDIENTE"?              
                globals.user.ROL==="gestor" || globals.user.ROL==="administrador"?
                  <FormPendiente recogida={row.rowData}/>
                :
                  <FormCancelacion recogida={row.rowData}/>
              :
              row.rowData.ESTADO==="PLANIFICADO"?
                globals.user.ROL==="gestor" || globals.user.ROL==="administrador"?
                  <FormPlanificadoGestor recogida={row.rowData}/>
                :
                  <FormPlanificadoProductor recogida={row.rowData}/>
              :
              row.rowData.ESTADO==="RECOGIDO"?
                globals.user.ROL==="gestor" || globals.user.ROL==="administrador"?
                  <FormRecogidoGestor recogida={row.rowData}/>
                :
                  <FormRecogidoProductor recogida={row.rowData}/>
              :
              row.rowData.ESTADO==="GESTIONADO"?
                globals.user.ROL==="gestor" || globals.user.ROL==="administrador"?
                  <FormGestionadoGestor recogida={row.rowData}/>
                :
                  <FormGestionadoProductor recogida={row.rowData}/>
              :
                globals.user.ROL==="gestor" || globals.user.ROL==="administrador"?
                  <FormGestionadoGestor recogida={row.rowData}/>
                :
                  <FormGestionadoProductor recogida={row.rowData}/>
              }
            </Container>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const Seguimiento = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filtroCentroGestor, setFiltroCentroGestor] = useState(null);
    const [filtroCentroProductor, setFiltroCentroProductor] = useState(null);
    const [filtroLer, setFiltroLer] = useState(null);
    const [filtroGestor, setFiltroGestor] = useState(null);
    const [filtroProductor, setFiltroProductor] = useState(null);
    const [filtroEstado, setFiltroEstado] = useState(null);
    const [filteredRows, setFilteredRows] = useState(null);
    const [loadingTable, setLoadingTable] = useState(true);
    const [resetKey, setResetKey] = useState(0);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    function uniqueArray (obj, index, self){
      return index === self.findIndex((o) => (
          o.label === obj.label
      ))
    };

    function filterOptions() {
      let filter_gest = []
      globals.Recogidas.forEach((row)=>{filter_gest.push({
          value: row.GESTOR,
          label: row.GESTOR,
        })})

      let filter_prod = []
      globals.Recogidas.forEach((row)=>{filter_prod.push({
          value: row.PRODUCTOR,
          label: row.PRODUCTOR,
        })})

      let filter_centro_gestor = []
      globals.Recogidas.forEach((row)=>{filter_centro_gestor.push({
          value: row.CENTRO_GESTOR,
          label: row.CENTRO_GESTOR,
        })})

      let filter_centro_productor = []
      globals.Recogidas.forEach((row)=>{filter_centro_productor.push({
          value: row.CENTRO_PRODUCTOR,
          label: row.CENTRO_PRODUCTOR,
        })})

      let filter_ler = []
      globals.Recogidas.forEach((row)=>{filter_ler.push({
          value: row.DESC_LER,
          label: row.DESC_LER,
        })})

      let filter_estado = []
      globals.Recogidas.forEach((row)=>{filter_estado.push({
        value: row.ESTADO,
        label: row.ESTADO,
      })
      })

      return [filter_gest.filter(uniqueArray), filter_ler.filter(uniqueArray), filter_prod.filter(uniqueArray), filter_centro_gestor.filter(uniqueArray), filter_estado.filter(uniqueArray), filter_centro_productor.filter(uniqueArray)]
  }


    useEffect(()=>{
      let newRow = []
      globals.Recogidas.forEach((row) => {
        if((filtroCentroGestor && !filtroCentroGestor.includes(row.CENTRO_GESTOR)) || (filtroLer && !filtroLer.includes(row.DESC_LER)) || (filtroGestor && !filtroGestor.includes(row.GESTOR)) || (filtroEstado && !filtroEstado.includes(row.ESTADO)) || (filtroProductor && !filtroProductor.includes(row.PRODUCTOR)) || (filtroCentroProductor && !filtroCentroProductor.includes(row.CENTRO_PRODUCTOR))){
          return
        }
        newRow.push(row)
      });
      setFilteredRows(newRow);
    }, [filtroCentroGestor, filtroLer, filtroProductor, filtroGestor, filtroEstado, loadingTable, filtroCentroProductor])

    useEffect(()=>{
      async function fetchSeguimiento(){
        const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
          method:'POST',
          headers: {
              'Content-Type': 'application/json',
              'authorization': process.env.REACT_APP_API_KEY,
              // 'authorization': process.env.REACT_APP_API_KEY,

          },
          body: JSON.stringify({
              'page':'Seguimineto',
              'token':globals.user.TOKEN,
          })
        })
  
        if(response.status !== 200){
          const message = await response.json();
          console.log(message)
          return
        }
  
        const seguimineto_data = await response.json();
        globals.Recogidas = seguimineto_data

        setLoadingTable(false)
      }

      fetchSeguimiento()
    },[])

    return(
      <>
      {
      loadingTable ?
      <Box sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        pointerEvents: "none"
        }}>
        <CircularProgress size={200}/>
      </Box>
      :
      <Grid item xs={12}>
      <Grid container columnGap={2}>
        <Grid xs={0.5} display="flex" minWidth="80px">
        <Typography alignSelf="center">
          Filtrar por:
        </Typography>
        </Grid>
        <Grid xs={1.5} minWidth="100px">
          <Autocomplete
            multiple
            options={filterOptions()[0]}
            getOptionLabel={(option)=>option.label}
            renderInput={(params) => (
              <TextField
              {...params}
              label="Gestor"
              />
          )}
          onChange={(event, newValue) => {
            if(newValue.length <= 0) {
              setFiltroGestor(null);
              return}
            let newFilter = [];
            newValue.map((res) => newFilter.push(res.value));
            setFiltroGestor(newFilter);
          }}
          />
        </Grid>
        <Grid xs={1.5} minWidth="100px">
          <Autocomplete
            multiple
            options={filterOptions()[3]}
            getOptionLabel={(option)=>option.label}
            renderInput={(params) => (
              <TextField
              {...params}
              label="Centro Gestor"
              />
          )}
          onChange={(event, newValue) => {
            if(newValue.length <= 0) {
              setFiltroCentroGestor(null);
              return}
            let newFilter = [];
            newValue.map((res) => newFilter.push(res.value));
            setFiltroCentroGestor(newFilter);
          }}
          />
        </Grid>
        <Grid xs={1.5} minWidth="100px">
          <Autocomplete
            multiple
            options={filterOptions()[2]}
            getOptionLabel={(option)=>option.label}
            renderInput={(params) => (
              <TextField
              {...params}
              label="Productor"
              />
          )}
          onChange={(event, newValue) => {
            if(newValue.length <= 0) {
              setFiltroProductor(null);
              return}
            let newFilter = [];
            newValue.map((res) => newFilter.push(res.value));
            setFiltroProductor(newFilter);
          }}
          />
        </Grid>
        <Grid xs={1.5} minWidth="100px">
          <Autocomplete
            multiple
            options={filterOptions()[5]}
            getOptionLabel={(option)=>option.label}
            renderInput={(params) => (
              <TextField
              {...params}
              label="Centro Productor"
              />
          )}
          onChange={(event, newValue) => {
            if(newValue.length <= 0) {
              setFiltroCentroProductor(null);
              return}
            let newFilter = [];
            newValue.map((res) => newFilter.push(res.value));
            setFiltroCentroProductor(newFilter);
          }}
          />
        </Grid>
        <Grid xs={1.5} minWidth="100px">
          <Autocomplete
            multiple
            options={filterOptions()[1]}
            getOptionLabel={(option)=>option.label}
            renderInput={(params) => (
              <TextField
              {...params}
              label="LER"
              />
          )}
          onChange={(event, newValue) => {
            if(newValue.length <= 0) {
              setFiltroLer(null);
              return}
            let newFilter = [];
            newValue.map((res) => newFilter.push(res.value));
            setFiltroLer(newFilter);
          }}
          />
        </Grid>
        <Grid xs={1.5} minWidth="100px">
          <Autocomplete
            multiple
            options={filterOptions()[4]}
            getOptionLabel={(option)=>option.label}
            renderInput={(params) => (
              <TextField
              {...params}
              label="Estado"
              />
          )}
          onChange={(event, newValue) => {
            if(newValue.length <= 0) {
              setFiltroEstado(null);
              return}
            let newFilter = [];
            newValue.map((res) => newFilter.push(res.value));
            setFiltroEstado(newFilter);
          }}
          />
        </Grid>
      </Grid>
      <Paper sx={{ width: '98.5%', overflow:"hidden", mt:"15px"}}>
      <TableContainer sx={{ maxHeight: "100%" }} key={resetKey}>
        <Table stickyHeader aria-label="sticky table" sx={{color:"red"}}>
          {/*TABLA HEADERS*/}
          <TableHead>
            <TableRow>
              <TableCell
              style={{ minWidth: 50, backgroundColor:"#2b6244" }}
              />
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, backgroundColor:"#2b6244", color:"white"}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows ?
            filteredRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return <Row rowData={row}/>
              }) 
            : globals.Recogidas
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return <Row rowData={row}/>
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filteredRows ? filteredRows.length : globals.Recogidas.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Resultados por pagina:"
        labelDisplayedRows={
          ({ from, to, count })=>{
            return '' + from + '-' + to + ' de ' + count
          }
        }
      />
    </Paper>
    </Grid>
    }
    </>
    )
}

export default Seguimiento