
export let globals = {
    user:"",
    navWith:"",
    seguimiento: "",
    formData:[],
    LERs:[],
    LER_Peligrosidad:{},
    Gestors:[],
    Productors:[],
    CentrosGest:{},
    CentrosProd:{},
    Contenedores:[],
    Recogidas:[],
    Documentos:[],
    Indicadores:false,
    Centros:[],
    Usuarios:[]
}

