export const columns = [
    {   
        id: 'GESTOR', 
        label: 'Gestor', 
        align: 'center',
        minWidth: 100,
        maxWidth: 150 },
    {   
        id: 'CENTRO_GESTOR', 
        label: 'Centro Gestor', 
        align: 'center',
        minWidth: 100,
        maxWidth: 150 },
    {   
        id: 'PRODUCTOR', 
        label: 'Productor', 
        align: 'center',
        minWidth: 100,
        maxWidth: 150 },
    {   
        id: 'CENTRO_PRODUCTOR', 
        label: 'Centro Productor', 
        align: 'center',
        minWidth: 100,
        maxWidth: 150 },
    {   
        id: 'DESC_LER', 
        label: 'LER', 
        align: 'center',
        minWidth: 100,
        maxWidth: 150 },
    {
        id: 'CONTRATO', 
        label: 'Contrato', 
        align: 'center',
        minWidth: 100,
        maxWidth: 150 },
    {
        id: 'NOTIFICACION', 
        label: 'Notificación Previa', 
        align: 'center',
        minWidth: 100,
        maxWidth: 120  
    },
    {
        id: 'CADUCIDAD', 
        label: 'Caducidad', 
        align: 'center',
        minWidth: 50,
        maxWidth: 70 },
    {
        id: 'TON_DISPONIBLES', 
        label: 'Tn', 
        align: 'center',
        minWidth: 50,
        maxWidth: 70 },
    ]