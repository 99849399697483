import React from "react";
import PbiEmbedded from "../../components/pbi_embedded/PbiEmbedded";

const Indicadores = () => {
  
    return(     
            <PbiEmbedded/>
          )
}

export default Indicadores