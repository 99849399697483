import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import SettingsIcon from '@mui/icons-material/Settings';

export const NavigationBarItems = [
    {
        id:0,
        icon: <LibraryAddIcon/>,
        label: 'Solicitar Recogida',
        route: 'solicitud',
    },
    {
        id:1,
        icon: <ContentPasteGoIcon/>,
        label: 'Seguimiento',
        route: 'seguimiento',
    },
    {
        id:2,
        icon: <InsertDriveFileIcon/>,
        label: 'Contratos',
        route: 'contratos',
    },
    {
        id:3,
        icon: <CalendarMonthIcon/>,
        label: 'Calendario',
        route: 'calendario',
    },
]

export const NavigationBarItemsReduc = [
    {
        id:0,
        icon: <ContentPasteGoIcon/>,
        label: 'Seguimiento',
        route: 'seguimiento',
    },
    {
        id:1,
        icon: <InsertDriveFileIcon/>,
        label: 'Contratos',
        route: 'contratos',
    },
    {
        id:2,
        icon: <CalendarMonthIcon/>,
        label: 'Calendario',
        route: 'calendario',
    }
]

export const NavigationBarAdmin = [
    {
        id:0,
        icon: <ContentPasteGoIcon/>,
        label: 'Seguimiento',
        route: 'seguimiento',
    },
    {
        id:1,
        icon: <InsertDriveFileIcon/>,
        label: 'Contratos',
        route: 'contratos',
    },
    {
        id:2,
        icon: <CalendarMonthIcon/>,
        label: 'Calendario',
        route: 'calendario',
    },
    {
        id:3,
        icon: <SettingsIcon/>,
        label: 'Administración',
        route: 'administracion'
    }
]