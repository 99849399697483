
import React from "react";
import MyCalendar from "../../components/calendar/Moment";

const Calendario = () => {
  return(     
    <MyCalendar/>
  )
}

export default Calendario