import React, { useState, useRef } from "react";
import { Grid, Paper, Autocomplete, TextField, Button, Typography, Stack, IconButton, InputAdornment, FormControl, InputLabel, OutlinedInput, List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { globals } from "../../data/Globals";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { MD5 } from "crypto-js";
import { UploadToBlob } from "../blob/blob_storage";
import CancelIcon from '@mui/icons-material/Cancel';
import { ModalCancelation } from "./ModalCancelation";

export default function FormPendiente({recogida}){
    const navigate = useNavigate();

    const [valueNumDI, setNumDI] = useState();
    const [valueTonelaje, setTonelaje] = useState();
    const [valueFecha, setFecha] = useState(dayjs(recogida.FECHA_RECOGIDA,"DD-MM-YYYY"));
    const [valuePrecio, setPrecio] = useState();

    const [errorNumDI, setErrorNumDI] = useState(false);
    const [errorTonelaje, setErrorTonelaje] = useState(false);
    const [errorFecha, setErrorFecha] = useState(false);
    const [errorPrecio, setErrorPrecio] = useState(false);

    const [msgNumDI, setMsgNumDI] = useState("");
    const [msgTonelaje, setMsgTonelaje] = useState("");
    const [msgFecha, setMsgFecha] = useState("");
    const [msgPrecio, setMsgPrecio] = useState("");

    const [loadingPendiente, setLoadingPendiente] = useState(false);

    const [DIDoc, SetDIDoc] = useState();
    const [AlbaranDoc, SetAlbaranDoc] = useState();
    const DIRef = useRef(null);
    const AlbaranRef = useRef(null);
    const [DIError, SetDIError] = useState(false);
    const [albaranError, SetAlbaranError] = useState(false);
    const [DIMsg, SetDIMsg] = useState("");
    const [albaranMsg, SetAlbaranMsg] = useState("");

    const handleDIUpdate = (event) => {
        SetDIDoc(event.target.files[0])
    }

    const handleAlbaranUpdate = (event) => {
        SetAlbaranDoc(event.target.files[0])
    }

    const [showCancelationPopup, setCancelationPopup] = useState(false);

    const openCancelationPopup = ()=>{
        setCancelationPopup(true)
    }

    const submitData = () => {
        let error = false

        async function insertPendiente(_diName, _albaranName){
            const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': process.env.REACT_APP_API_KEY,
                },
                body: JSON.stringify({
                    'page':'Pendiente',
                    'token':globals.user.TOKEN,
                    'id': recogida.ID_RECOGIDA,
                    'di': valueNumDI,
                    'tonelaje': valueTonelaje,
                    'precio': valuePrecio,
                    'fechaRecogida': valueFecha.format("DD-MM-YYYY"),
                    'ficha':_diName,
                    'albaran':_albaranName
                })
            })

            if(response.status !== 200){
                const message = await response.json();
                console.log(message)
                return
            }          

            setLoadingPendiente(false)
            navigate(0)
        }

        if(!valueNumDI){
            error = true
            setErrorNumDI(true)
            setMsgNumDI("Campo obligatorio")
        }
        if(!valueTonelaje){
            error = true
            setErrorTonelaje(true)
            setMsgTonelaje("Campo obligatorio")
        }
        if(!valueFecha){
            setErrorFecha(true)
            setMsgFecha("Campo obligatorio")
            error = true
        }
        if(!valuePrecio){
            setErrorPrecio(true)
            setMsgPrecio("Campo obligatorio")
            error = true
        }
        if(valueTonelaje < 0){
            error = true
            setErrorTonelaje(true)
            setMsgTonelaje("Número no válido")
        }
        // if(valuePrecio < 0){
        //     setErrorPrecio(true)
        //     setMsgPrecio("Número no válido")
        //     error = true
        // }
        // if(!AlbaranDoc){
        //     SetAlbaranError(true)
        //     SetAlbaranMsg("Campo obligatorio")
        //     error = true
        // }
        if(!DIDoc){
            SetDIError(true)
            SetDIMsg("Campo obligatorio")
            error = true
        }

        if(!error){
            setLoadingPendiente(true)

            // const token = MD5(new Date().getTime()).toString()
            const token = MD5(recogida.ID_RECOGIDA + valueNumDI + valueFecha.format("DD-MM-YYYY")).toString()

            const diName = DIDoc.name.replace(".pdf",token+".pdf");
            UploadToBlob(DIDoc, diName);

            let albaranName = ""

            if(AlbaranDoc){
                albaranName = AlbaranDoc.name.replace(".pdf",token+".pdf");
                UploadToBlob(AlbaranDoc, albaranName);
            }

            insertPendiente(diName, albaranName)
        }
        
    }

    return(
        <Stack fullWidth mb={3}>
        <Typography fontSize={24} m={1}>
            <b>Formulario de confirmación, id:{recogida.ID_RECOGIDA}</b> 
        </Typography>
        <Paper elevation={3} sx={{marginTop:0, marginBottom:2}}>
            <Grid container rowGap={2} sx={{
                display:"flex",
                justifyContent:"space-evenly",
                marginTop:"20px",
                marginBottom:"20px",
                paddingTop:"20px",
                paddingBottom:"20px"
            }}>
                <Grid item xs={5.8}>
                    <TextField 
                    fullWidth
                    label="Nº DI o FS" 
                    value={valueNumDI}
                    error={errorNumDI}
                    helperText={msgNumDI}
                    onChange={(event)=>{
                        setNumDI(event.target.value);
                        setErrorNumDI(false);
                        setMsgNumDI("");
                    }}
                    required
                    />
                </Grid>
                <Grid item xs={5.8}>
                    <TextField 
                    fullWidth
                    label="Tonelaje Teórico" 
                    type={"number"} 
                    InputProps={{
                        endAdornment:<InputAdornment position="end">Tn</InputAdornment>                        
                    }}
                    inputProps={{
                        min: 0
                    }}
                    value={valueTonelaje}
                    error={errorTonelaje}
                    helperText={msgTonelaje}
                    onChange={(event)=>{
                        setTonelaje(event.target.value);
                        setErrorTonelaje(false);
                        setMsgTonelaje("");
                    }}
                    required
                    />
                </Grid>
                <Grid item xs={5.8}>
                    <DatePicker 
                        label="Fecha de confirmación" 
                        value={valueFecha}
                        onChange={(value) => {setFecha(value)}}
                        format="DD/MM/YYYY" 
                        sx={{width:"100%"}}
                    />
                </Grid>
                <Grid item xs={5.8}>
                    <TextField 
                    fullWidth 
                    label="Precio"
                    type={"number"} 
                    InputProps={{
                        endAdornment:<InputAdornment position="end">€/Tn</InputAdornment>
                    }}
                    // inputProps={{
                    //     min: 0
                    // }}
                    value={valuePrecio}
                    error={errorPrecio}
                    helperText={msgPrecio}
                    onChange={(event)=>{
                        setPrecio(event.target.value);
                        setErrorPrecio(false);
                        setMsgPrecio("");
                    }}
                    required
                    />
                </Grid>
                <Grid item xs={12} mt={3}>
                    <Divider variant="middle"/>
                </Grid>
                <Grid xs={11.8}>
                    <Typography fontSize={24} m={1}>
                        Subida de Documentos
                    </Typography>
                </Grid>
                <Grid item xs={5.75}>
                    <FormControl fullWidth>
                        <InputLabel>Ficha de DI o Ficha de Seguimiento</InputLabel>
                        <OutlinedInput
                            label="Ficha de DI o Ficha de Seguimiento"
                            readOnly    
                            error={DIError}
                            helperText={DIMsg}
                            value={DIDoc ? DIDoc.name : ""}
                            onClick={()=>(DIRef.current.click())}
                            endAdornment={
                                <InputAdornment>
                                    <IconButton component="label" sx={{pointerEvents:"none"}}>
                                        <input hidden accept=".pdf" ref={DIRef} type="file" onChange={(event)=>{handleDIUpdate(event); SetDIError(false); SetDIMsg("")}}/>
                                        <InsertDriveFileIcon/>
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={5.75}>
                    <FormControl fullWidth>
                        <InputLabel>Albarán</InputLabel>
                        <OutlinedInput
                            label="Albarán"
                            readOnly    
                            error={albaranError}
                            helperText ={albaranMsg}
                            value={AlbaranDoc ? AlbaranDoc.name : ""}
                            onClick={()=>(AlbaranRef.current.click())}
                            endAdornment={
                                <InputAdornment>
                                    <IconButton component="label" sx={{pointerEvents:"none"}}>
                                        <input hidden accept=".pdf" ref={AlbaranRef} type="file" onChange={(event)=>{handleAlbaranUpdate(event); SetAlbaranError(false); SetAlbaranMsg("")}}/>
                                        <InsertDriveFileIcon/>
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} mt={2}/>
                <Grid item xs={10.3} display="flex" alignContent="end" mt={6}/>
                    {loadingPendiente ?
                    <LoadingButton loading loadingPosition="end" endIcon={<SendIcon/>} variant="contained">
                        Planificar
                    </LoadingButton>
                    :
                    <Button variant="contained" color="secondary" endIcon={<SendIcon/>} onClick={submitData}>
                        Planificar
                    </Button>
                    } 
                </Grid>
        </Paper>
        <Grid container display="flex" justifyContent="space-between">
        <Grid item xs={3}>
        <Paper elevation={3}>
            <Grid container pr={2}>
                <Grid item xs={12}>
                    <Typography fontSize={24} m={2}>
                        <b>Documentos Adjuntos:</b> 
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <List>
                        <ListItem
                            secondaryAction={
                                <IconButton edge="end" size="small" component="a" href={recogida.CONTRATO} target="_blank" rel="noopener noreferrer">
                                    <FindInPageIcon fontSize="small"/>
                                </IconButton>
                            }
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    <InsertDriveFileIcon/>
                                </Avatar>    
                            </ListItemAvatar>
                            <ListItemText 
                                sx={{overflow:"hidden"}}
                                primary="Contrato"
                                secondary={recogida.CONTRATO.replace("https://bsekologica1.blob.core.windows.net/ekogest/ekogest/","").replace(recogida.CONTRATO.substr(-36),".pdf")}
                            />    
                        </ListItem>
                        {globals.LER_Peligrosidad[recogida.DESC_LER] ?
                        <ListItem
                            secondaryAction={
                                <IconButton edge="end" size="small" component="a" href={recogida.CONTRATO} target="_blank" rel="noopener noreferrer">
                                    <FindInPageIcon fontSize="small"/>
                                </IconButton>
                            }
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    <InsertDriveFileIcon/>
                                </Avatar>    
                            </ListItemAvatar>
                            <ListItemText 
                                sx={{overflow:"hidden"}}
                                primary={<Stack><Typography>
                                        NP o FA
                                        </Typography>
                                        <Typography color="secondary" fontWeight={900}>
                                        {"(" + recogida.TON_DISP + "Tn disponibles)"} 
                                        </Typography></Stack>}
                                secondary={recogida.NOTIFICACION.replace("https://bsekologica1.blob.core.windows.net/ekogest/ekogest/","").replace(recogida.NOTIFICACION.substr(-36),".pdf")}
                            />    
                        </ListItem>
                        : <></>}
                    </List>
                </Grid>
            </Grid>
        </Paper>
        </Grid>
        <Grid item xs={8.8}>
            <Paper elevation={3}>
                <Grid Grid container pr={2} pb={2.15} pl={2}>
                    <Grid item xs={12}>
                        <Typography fontSize={24} mt={2} mb={2}>
                            <b>Datos Adicionales:</b> 
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                    <Grid container rowGap={3} mb={1}>
                        <Grid item>
                            <TextField
                            label="Tipo de Contenedores"
                            variant="outlined"
                            defaultValue={recogida.TIPO_CONTENEDOR}
                            sx={{
                                pointerEvents:"none"
                            }}
                            inputProps={{
                                readOnly: true
                            }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                            label="Número de contenedores"
                            variant="outlined"
                            defaultValue={recogida.RECOGIDA_CONTENEDOR + " - ("+ recogida.SUBSTITUDION_CONTENEDOR +" a substituir)"}
                            sx={{
                                pointerEvents:"none"
                            }}
                            inputProps={{
                                readOnly: true
                            }}
                            />
                        </Grid>                        
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
        </Grid>
        <Grid container display="flex" justifyContent="end" mt={4} mb={2}>
                <Grid item xs={12}display="flex" justifyContent="end">
                    <Button variant="contained" endIcon={<CancelIcon/>} size="large" color="error" onClick={openCancelationPopup}>
                        Cancelar Recogida
                    </Button>
                </Grid>
            </Grid>
            <ModalCancelation open={showCancelationPopup} onClose={()=>setCancelationPopup(false)} id={recogida.ID_RECOGIDA}/>
        </Stack>
    )
}