import './App.css';
import React, { useState, useEffect, createContext } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import NavigationBar from './components/navigation_bar/NavigationBar'
import TopBar from './components/top_bar/TopBar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { esES } from '@mui/x-date-pickers';
import { esES as dataGridEsES } from '@mui/x-data-grid';
import { esES as coreEsES } from '@mui/material/locale';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { globals } from "./data/Globals";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box'
import FrontPage from './pages/front_page/FrontPage';

const theme = createTheme({
  palette:{
    primary:{
      main:"#8cba37",
    },
    secondary:{
       main:"#2b6244",
    },
  },
  esES,
  dataGridEsES,
  coreEsES,
});

export const NavContext = createContext();
export const PermitContext = createContext();

function App() {
  const navigate = useNavigate();

  dayjs.locale('es');
  const [title, setTitle] = useState(null);
  const location = useLocation();
  const [loadingPage, setLoadingPage] = useState(false);

  useEffect(() => {
    const parseTitle = location.pathname.replace(/\W/g,'');
    setTitle(parseTitle);
  },[location])
  

  useEffect(() => {
    let user_token = localStorage.getItem("ekogest_user_token");
    let user = sessionStorage.getItem("ekogest_user")

    async function fetchUser(_user_token){
      const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify({
            'page':'validation',
            'token':_user_token,
        })
      })

      if(response.status !== 200){
        const message = await response.json();
        console.log(message)
        setLoadingPage(false)
        return
      }

      const user_object = await response.json();
      globals.user = user_object;
      const user_string = JSON.stringify(user_object)

      sessionStorage.setItem("ekogest_user", user_string);   
      setPermit(true);       
      setLoadingPage(false);

    }

    async function fetchFormData(){
      const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
          method:'POST',
          headers: {
              'Content-Type': 'application/json',
              'authorization': process.env.REACT_APP_API_KEY,
          },
          body: JSON.stringify({
              'page':'solicitude',
              'token': user_token,
          })
      })

      if(response.status !== 200){
          const message = await response.json();
          console.log(message)
          return
      }

      const form_object = await response.json();

      globals.formData = form_object
    }

    async function checkIndicadores(){
      const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify({
            'page':'Indicadores',
            'token': user_token
        })
      })

      if(response.status !== 200){
        const message = await response.json();
        console.log(message)
        return
      }

      const validateIndicadores = await response.json();
      globals.Indicadores = validateIndicadores
      console.log(validateIndicadores)
    }


    if(user_token !== null){      
      if(user){
        globals.user = JSON.parse(user);
        fetchFormData()
        checkIndicadores()
        setPermit(true)
      }else{
        setLoadingPage(true)

        fetchUser(user_token)
        .then(fetchFormData())
        .then(checkIndicadores())
      }
      
    }
    else{
      console.log("no token");
      navigate('/');
    }
  },[])

  useEffect(()=>{
    async function fetchLER(_user_token){
      const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify({
            'page':'LER'
        })
      })

      if(response.status !== 200){
        const message = await response.json();
        console.log(message)
        return
      }

      const LER_arr = await response.json();

      globals.LERs = []

      LER_arr.reverse().forEach(element => {
        globals.LERs.push(element.DESC_LER +" - "+ element.COD_LER)
        globals.LER_Peligrosidad[element.DESC_LER +" - "+ element.COD_LER] = element.FLAG_PELIGROSIDAD
      });
    }
    
    fetchLER()
  },[])

  useEffect(()=>{
    async function fetchGestor(_user_token){
      const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify({
            'page':'Gestor'
        })
      })

      if(response.status !== 200){
        const message = await response.json();
        console.log(message)
        return
      }

      const gest_arr = await response.json();

      gest_arr.forEach(element => {
        globals.Gestors.push(element.GESTOR)
      });
    }
    
    fetchGestor()

  },[])

  useEffect(()=>{
    async function fetchProductor(_user_token){
      const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify({
            'page':'Productor'
        })
      })

      if(response.status !== 200){
        const message = await response.json();
        console.log(message)
        return
      }

      const gest_arr = await response.json();

      gest_arr.forEach(element => {
        globals.Productors.push(element.PRODUCTOR)
      });
    }
    
    fetchProductor()

  },[])

  useEffect(()=>{
    async function fetchCentros(_user_token){
      const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify({
            'page':'Centro'
        })
      })

      if(response.status !== 200){
        const message = await response.json();
        console.log(message)
        return
      }

      const gest_arr = await response.json();

      gest_arr.forEach(element => {

        if (element.TIPO == "gestor"){
          if (globals.CentrosGest.hasOwnProperty(element.PROPIETARIO)){
            globals.CentrosGest[element.PROPIETARIO].push(element.CENTRO)
          }
          else{
            globals.CentrosGest[element.PROPIETARIO] = [element.CENTRO]
          }
        }  
        else{
          if (globals.CentrosProd.hasOwnProperty(element.PROPIETARIO)){
            globals.CentrosProd[element.PROPIETARIO].push(element.CENTRO)
          }
          else{
            globals.CentrosProd[element.PROPIETARIO] = [element.CENTRO]
          }
        }      
      });
    }
    
    fetchCentros()

  },[])

  useEffect(()=>{
    async function fetchContenedor(){
      const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify({
            'page':'Contenedor'
        })
      })

      if(response.status !== 200){
        const message = await response.json();
        console.log(message)
        return
      }

      const contenedorARR = await response.json();
      globals.Contenedores = []

      contenedorARR.forEach(element => {
        globals.Contenedores.push(element.CONTENEDOR)
      });
    }
    
    fetchContenedor()

  },[])

  const [navWith, setNavWidth] = useState(60);
  const [permit, setPermit] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
      <PermitContext.Provider value={[permit, setPermit]}>
      <NavContext.Provider value={[navWith, setNavWidth]}>
      <CssBaseline/>
      {loadingPage?
      <Box sx={{
        position: 'absolute',
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        pointerEvents: "none"
        }}>
        <CircularProgress size={200}/>
      </Box>
      :
      <Grid container color="primary" sx={{marginTop:"80px", paddingLeft:(navWith + 40)+"px", marginBottom:"30px"}}>
        <TopBar title_page={title}/>
        {permit ? <NavigationBar/> : <></>}
        {permit ? <Outlet/> : <FrontPage/>}
      </Grid>
      }
      </NavContext.Provider>
      </PermitContext.Provider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
