import React, { useContext, useEffect, useState } from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Grid } from "@mui/material";
import InsertChartRoundedIcon from '@mui/icons-material/InsertChartRounded';
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../images/TB3.png"
import backgroundButton from "../../images/NB.jpg"
import { globals } from "../../data/Globals";
import { user_list } from "../../data/Globals";
import { PermitContext } from "../../App";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { set } from "react-hook-form";



export default function TopBar({title_page}){
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  useEffect(()=>{
    let user_token = localStorage.getItem("ekogest_user_token");

    async function checkIndicadores(){
      const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify({
            'page':'Indicadores',
            'token': user_token
        })
      })

      if(response.status !== 200){
        const message = await response.json();
        console.log(message)
        return
      }

      const validateIndicadores = await response.json();
      globals.Indicadores = validateIndicadores
      setResetKey((value)=>value+1)
      console.log(validateIndicadores)
    }

    if(user_token !== null){ 
      checkIndicadores()
    }

  },[])

    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };

    const settings = [{
      label:'Cerrar Sesión',
      action:()=>{  sessionStorage.removeItem("ekogest_user");
                    localStorage.removeItem("ekogest_user_token");
                    navigate(0)}
    }];

    const [permit, setPermit] = useContext(PermitContext);
    const [resetKey, setResetKey] = useState(0)

    return(
        <AppBar position="fixed" color="primary" key={resetKey} sx={{
            backgroundImage: `url(${backgroundImage})`, //paddingLeft:navWith+'px',
            backgroundPositionX: "center",
            backgroundSize: "cover",
            zIndex:"1400",
            
        }}>
          <Toolbar flexGrow={0} disableGutters sx={{justifyContent:"space-between"}}>
            <Grid container display="flex" justifyContent="space-between" pl={2} pr={4}>
            <Grid item xs={3} display="flex" alignItems="end" pt={1} pb={1}>
            <img src="https://ekologica1bdd8.blob.core.windows.net/imagenes/ekogest_by_ekologica_logo.png" alt="Logo" height="50" style={{marginLeft: "1px"}} onClick={()=>{navigate("/")}} />
            </Grid>
            <Grid item xs={4} sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent:"center" }}} >
              <Typography fontSize={38} textTransform="capitalize" sx={{
                fontWeight: 700,
                letterSpacing: '.2rem',
                color: 'inherit',
                display: 'flex',
                alignItems: 'center'
              }}>
                <b>{permit ? ()=>{setPermit(true); return title_page;} : ""}</b>
              </Typography>
            </Grid>
            {permit ? <Grid item xs={3} display="flex" justifyContent="end" alignItems="center">
            {!globals.Indicadores ? 
            <></>
            :
            <Button variant="contained" endIcon={<InsertChartRoundedIcon/>} sx={{marginRight:2, minWidth:"150px", height:"40px", color:"white", backgroundImage: `url(${backgroundButton})`, }} onClick={() => navigate("indicadores")}>
              Indicadores
            </Button>
            }
              <Tooltip title="Perfil">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, justifySelf:"end"}}>
                  <Avatar sx={{backgroundColor:"white"}} src={globals.user.AVATAR} variant="rounded"/>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting.label} onClick={setting.action}>
                    <Typography textAlign="center">{setting.label}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Grid> :
            <Grid item xs={3} display="flex" alignItems="center" justifyContent="end">
            <Button variant="contained" endIcon={<VpnKeyIcon/>} sx={{marginRight:2, minWidth:"100px", color:"white", height:"40px", backgroundImage: `url(${backgroundButton})`, }} onClick={() => navigate("login")}>
            Acceder
            </Button>
            </Grid>
            }
            </Grid>
          </Toolbar>
      </AppBar>
    )
}