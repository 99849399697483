import { BlobServiceClient } from "@azure/storage-blob";

const containerName = 'ekogest';

const uploadUrl = 'https://bsekologica1.blob.core.windows.net/ekogest?sp=racwdlmeop&st=2023-04-17T13:33:33Z&se=2043-04-17T21:33:33Z&sip=0.0.0.0-255.255.255.255&sv=2021-12-02&sr=c&sig=x8Q8w0JgSdWYh0RlCS6cgjndekI4W1QQv6T12bPm6Ik%3D';
const blobService = new BlobServiceClient(uploadUrl);

const containerClient = blobService.getContainerClient(containerName);

const createBlobInContainer = async (file, name) => {
  const file_name = name

  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(file_name);

  // set mimetype as determined from browser with file upload control
  const options = { blobHTTPHeaders: { blobContentType: "application/pdf" } };

  const arrayBuffer = await file.arrayBuffer();
  const uint8Array = new Uint8Array(arrayBuffer);

  const blob = new Blob([uint8Array], { type: "application/pdf" });
  await blobClient.upload(blob, blob.size, options);
};

export const UploadToBlob = async (file, name) => {
  if (!file) return;
  // upload file
  await createBlobInContainer(file, name);
};
