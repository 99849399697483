import React, { useState, useEffect } from 'react';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/es'; // import the locale
import dayjs from 'dayjs';
import { EventModal } from './EventModal';
import { Box, CircularProgress } from '@mui/material';
import { globals } from '../../data/Globals';

// const localizer = momentLocalizer(moment);
// moment.locale('es'); // set the locale to Spanish

const localizer = dayjsLocalizer(dayjs)

const _events = [
  {
    start: dayjs("03-04-2023", "DD-MM-YYYY").toDate(),
    end: dayjs("03-04-2023", "DD-MM-YYYY").toDate(),
    title: 'Ejemplo pendiente',
    description: 'This is event 1',
    backgroundColor: '#C2D0D8',
  },
  {
    start:  dayjs("05-04-2023", "DD-MM-YYYY").toDate(),
    end:  dayjs("05-04-2023", "DD-MM-YYYY").toDate(),
    title: 'Ejemplo planificado',
    description: 'This is event 2',
    backgroundColor: '#d7d8c2'
  },
  {
    start: dayjs("07-04-2023", "DD-MM-YYYY").toDate(),
    end: dayjs("07-04-2023", "DD-MM-YYYY").toDate(),
    title: 'Ejemplo gestionado',
    description: 'This is event 3',
    backgroundColor: "#c2d8c3",
  }
];

const events = () => {
  let result = [];
  globals.Recogidas.forEach((element)=>{
    result.push({
      start: dayjs(element.FECHA_RECOGIDA, "DD-MM-YYYY").toDate(),
      end: dayjs(element.FECHA_RECOGIDA, "DD-MM-YYYY").toDate(),
      title: element.ID_RECOGIDA + "-" + (globals.user.ROL === "gestor" ? element.PRODUCTOR : element.GESTOR),
      backgroundColor: element.ESTADO==="PENDIENTE" ? "#C2D0D8" : element.ESTADO==="PLANIFICADO" ? "#d7d8c2" : element.ESTADO==="GESTIONADO" ? "#c2d8c3" : element.ESTADO==="CANCELADO" ? "#ffa3a3":"#dbdbdb",
      data: element
    })
  })
  return result
}

// const CustomToolbar = (toolbar) => {
//   const goToBack = () => {
//     toolbar.onNavigate('Back');
//   };

//   const goToNext = () => {
//     toolbar.onNavigate("Next");
//   };

//   const goToToday = () => {
//     toolbar.onNavigate('Hoy');
//   };

//   const label = () => {
//     const date = moment(toolbar.date);
//     return (
//       <span>{date.format('MMMM').toUpperCase()} {date.format('YYYY')}</span>
//     );
//   };

//   return (
//     <div className="rbc-toolbar">
//       <div className="rbc-toolbar-label">{label()}</div>
//       <div className="rbc-btn-group">
//         <button type="button" onClick={goToBack}>
//           {'<'}
//         </button>
//         <button type="button" onClick={goToToday}>
//           Hoy
//         </button>
//         <button type="button" onClick={goToNext}>
//           {'>'}
//         </button>
//       </div>
//     </div>
//   );
// };

function eventStyleGetter(event, start, end, isSelected) {
  const backgroundColor = event.backgroundColor;
  const style = {
    backgroundColor,
    color: 'black',
  };
  return {
    style: style
  };
}

const MyCalendar = () => {
  const [selectedEvent, setSelectedEvent] = React.useState(null);
  const [loadingCalendar, setLoadingCalendar] = useState(true);
  const [eventData, setEventData] = useState({ID:"",ESTADO:"", GESTOR:"", PRODUCTOR:"", DESC_LER:"", TON_TEORICO:"", CENTRO:"", FECHA_RECOGIDA:""});

  useEffect(()=>{
    async function fetchSeguimiento(){
      const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify({
            'page':'Seguimineto',
            'token':globals.user.TOKEN,
        })
      })

      if(response.status !== 200){
        const message = await response.json();
        console.log(message)
        return
      }

      const seguimineto_data = await response.json();
      globals.Recogidas = seguimineto_data
      console.log(globals.Recogidas)
      setLoadingCalendar(false)
    }

    fetchSeguimiento()
  },[])

  const handleSelectEvent = (event) => {
    setSelectedEvent({
      ...event,
    });
  };

  const [showEvent, handlePopupEvent] = useState(false)

  return (
    <>
      {
        loadingCalendar?
        <Box sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "none"
          }}>
          <CircularProgress size={200}/>
        </Box>
        :
        <Calendar
        localizer={localizer}
        events={events()}
        startAccessor="start"
        endAccessor="end"
        views={{
          month:true,
          week:true,
          agenda:true
        }}
        style={{ height: '80vh', width: '80vw' }}
        
        popup
        // components={{
        //   toolbar: CustomToolbar,
        // }}
        onSelectEvent={(event) => {handleSelectEvent(event); setEventData(event.data); handlePopupEvent(true)}}
        eventPropGetter={eventStyleGetter}
      />
      }
      <EventModal open={showEvent} onClose={()=>handlePopupEvent(false)} data={eventData}/>
    </>
  );
};

export default MyCalendar;
