import React, { useRef, useEffect, useContext } from "react";
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
//import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { NavigationBarItems, NavigationBarItemsReduc, NavigationBarAdmin } from "./navigation_bar_items/NavigationBarItems";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../images/NB.jpg";
import { NavContext } from "../../App";
import { globals } from "../../data/Globals";

const drawerWidth = 220;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function NavigationBar(){
    const [open, setOpen] = React.useState(false);
    const [navWith, setNavWidth] = useContext(NavContext);
    const navigate = useNavigate();
    const drawerRef = useRef(null);

    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };

    useEffect(()=>{
      const handleTransition = () => {
        setNavWidth(drawerNode.offsetWidth);
      }
    
      const drawerNode = drawerRef.current;
      drawerNode.addEventListener('transitionend', handleTransition);

      return () => {
        drawerNode.removeEventListener('transitionend', handleTransition);
      };
    }, [navWith, setNavWidth])

    return(
        <Drawer 
        ref={drawerRef}
        variant="permanent" 
        open={open}
        sx={{
          flexShrink:0,
          '& .MuiDrawer-paper':{
          backgroundImage: `url(${backgroundImage})`,
          color:"#d6d6d6",}
        }}>
        <DrawerHeader/>
        <DrawerHeader>
          <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
            {open ? <ChevronLeftIcon sx={{color:"#d6d6d6",}} /> : <ChevronRightIcon sx={{color:"#d6d6d6",}} />}
          </IconButton>
        </DrawerHeader>
        <Divider/>
        <List>
            {localStorage.getItem("ekogest_user_token") === process.env.REACT_APP_ADMIN_TOKEN ?
              NavigationBarAdmin.map((item, index) =>(
                <ListItem
                key={item.id} 
                disablePadding 
                sx={{ display: 'block' }}>
                    <ListItemButton
                    onClick={() => navigate(item.route)}
                    sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    }}>
                        <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            color:"white",
                        }}>
                        {item.icon}                            
                        </ListItemIcon>
                        <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0, color:"white" }}/>
                    </ListItemButton>
                </ListItem>
            ))
            :
            globals.user.ROL==="gestor" || globals.user.ROL==="administrador"?
                NavigationBarItemsReduc.map((item, index) =>(
                <ListItem
                key={item.id} 
                disablePadding 
                sx={{ display: 'block' }}>
                    <ListItemButton
                    onClick={() => navigate(item.route)}
                    sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    }}>
                        <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            color:"white",
                        }}>
                        {item.icon}                            
                        </ListItemIcon>
                        <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0, color:"white" }}/>
                    </ListItemButton>
                </ListItem>
            ))
            :
            NavigationBarItems.map((item, index) =>(
              <ListItem
              key={item.id} 
              disablePadding 
              sx={{ display: 'block' }}>
                  <ListItemButton
                  onClick={() => navigate(item.route)}
                  sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  }}>
                      <ListItemIcon
                      sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                          color:"white",
                      }}>
                      {item.icon}                            
                      </ListItemIcon>
                      <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0, color:"white" }}/>
                  </ListItemButton>
              </ListItem>
            ))
            }
        </List>    
      </Drawer>
    )
}