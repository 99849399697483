import React from "react";
import { Box, Button, Chip, Grid, Modal, Paper, Typography } from "@mui/material";
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';

export const EventModal = ({open, onClose, data})=>{
    return(
        <Modal open={open} onClose={onClose} sx={{zIndex:"1500"}}>
            <Box
            sx={{
            position: 'absolute',
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pointerEvents: "none"
            }}>
                <Paper sx={{width: "60vw", minWidth: "600px", display: "flex", alignItems: "center", pointerEvents:"auto"}}>
                    <Grid container display="flex" justifyContent="space-evenly" mt={5} mb={5}>
                        <Grid xs={5}>
                            <Typography fontSize={32}>
                                <b>Recogida Nº{data.ID_RECOGIDA}</b>
                            </Typography>
                        </Grid>
                        <Grid xs={5} display="flex" alignItems="center" justifyContent="end">
                            <Chip
                            label={<Typography fontSize={32} mt={3} mb={3}>
                                    {data.ESTADO}
                                </Typography>} 
                            sx={{backgroundColor: data.ESTADO==="PENDIENTE" ? "#C2D0D8" : data.ESTADO==="PLANIFICADO" ? "#d7d8c2" : data.ESTADO==="GESTIONADO" ? "#c2d8c3" : data.ESTADO==="CANCELADO" ? "#ffa3a3":"#dbdbdb"}}
                            size="large"
                            />
                        </Grid>
                        <Grid xs={5} mt={4}>
                            <Typography fontSize={24}>
                                <b>Gestor</b>
                            </Typography>
                        </Grid>
                        <Grid xs={5} mt={4}>
                            <Typography fontSize={24}>
                                <b>Productor</b>
                            </Typography>
                        </Grid>
                        <Grid xs={5}>
                            <Typography fontSize={24}>
                                {data.GESTOR}
                            </Typography>
                        </Grid>
                        <Grid xs={5}>
                            <Typography fontSize={24}>
                                {data.PRODUCTOR}
                            </Typography>
                        </Grid>
                        <Grid xs={5} mt={4}>
                            <Typography fontSize={24}>
                                <b>Centro Gestor</b>
                            </Typography>
                        </Grid>
                        <Grid xs={5} mt={4}>
                            <Typography fontSize={24}>
                                <b>Centro Productor</b>
                            </Typography>
                        </Grid>
                        <Grid xs={5}>
                            <Typography fontSize={24}>
                                {data.CENTRO_GESTOR}
                            </Typography>
                        </Grid>
                        <Grid xs={5}>
                            <Typography fontSize={24}>
                                {data.CENTRO_PRODUCTOR}
                            </Typography>
                        </Grid>                        
                        <Grid xs={5} mt={4}>
                            <Typography fontSize={24}>
                                <b>LER</b>
                            </Typography>
                        </Grid>
                        <Grid xs={5} mt={4}>
                            <Typography fontSize={24}>
                                <b>Tonelaje</b>
                            </Typography>
                        </Grid>
                        <Grid xs={5}>
                            <Typography fontSize={24}>
                                {data.DESC_LER}
                            </Typography>
                        </Grid>
                        <Grid xs={5}>
                            <Typography fontSize={24}>
                                {data.TON_TEORICO + " Tn"}
                            </Typography>
                        </Grid>
                        <Grid xs={5} mt={4}>
                            <Typography fontSize={24}>
                                <b>Fecha de Recogida</b>
                            </Typography>
                        </Grid>
                        <Grid xs={5} mt={4}>
                            <Typography fontSize={24}>
                                <b>Tipo de Contenedor</b>
                            </Typography>
                        </Grid>
                        <Grid xs={5}>
                            <Typography fontSize={24}>
                                {data.FECHA_RECOGIDA}
                            </Typography>
                        </Grid>
                        <Grid xs={5}>
                            <Typography fontSize={24}>
                                {data.TIPO_CONTENEDOR + " - " + data.RECOGIDA_CONTENEDOR + " (" +data.SUBSTITUDION_CONTENEDOR+ " a substituir)"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Modal>
    )
}