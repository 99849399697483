import React from "react";
import { Grid } from "@mui/material";
import backgroundHome from "../../images/BG1.png";

const Home = () => {
  
    return(     
        <Grid position="absolute" top={0} left={0}  container sx={{backgroundImage: `url(${backgroundHome})`,backgroundRepeat: "no-repeat",backgroundPosition: "center", backgroundSize:"cover", height:"100vh"}}>

        </Grid>
    )
}

export default Home